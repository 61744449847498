import { queryOptions } from '@tanstack/react-query';

import { useAccessToken } from 'utils/api/api';
import { PageResponse, Pagination } from 'models/Page.models';
import { Offer, OfferEligibility } from 'models/referentiels/Offer.model';
import {
  fetchOfferByCode,
  fetchOfferByCodeEligibility,
  fetchOfferDocuments,
  fetchOffers,
  fetchOffersEligibility,
  fetchOfferWarranties,
  OfferFilters,
} from 'api/referentiels/Offer.api';
import { PersonStatusFilters } from 'models/referentiels/PersonStatus.models';
import { fetchPersonStatus, fetchPersonStatusByCode, fetchPersonStatusPage } from './PersonStatus.api';

export const personStatusQueries = {
  getAll: (filters?: PersonStatusFilters, sort?: string[]) =>
    personStatusGetAllOptions(filters, sort),
  getPage: (pagination?: Pagination, filters?: PersonStatusFilters, sort?: string[]) =>
    personStatusGetPageOptions(pagination, filters, sort),
  getByCode: (code: string) => personStatusGetByCodeOptions(code)
};

export const personStatusGetPageOptions = (
  pagination?: Pagination,
  filters?: OfferFilters,
  sort?: string[],
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['person-status', pagination, filters, sort],
    queryFn: () => {
      return fetchPersonStatusPage(token, pagination, filters, sort);
    },
  });
};

export const personStatusGetAllOptions = (
  filters?: PersonStatusFilters,
  sort?: string[],
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['person-status', filters, sort],
    queryFn: () => {
      return fetchPersonStatus(token, filters, sort);
    },
  });
};

export const personStatusGetByCodeOptions = (code: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['person-status', code],
    queryFn: () => {
      return fetchPersonStatusByCode(token, code);
    },
  });
};
