import React, { useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Link, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { Grid, Link as LinkMUI } from '@mui/material';
import { appName } from 'App';
import AppBar from 'components/AppBar';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { PersonFormValues } from 'models/Form.model';
import { Person, PersonRequestPostErrors } from 'models/oav/Person.models';
import IdentityForm from 'content/form/PersonForm/IdentityForm';
import ContactInfoForm from 'content/form/PersonForm/ContactInfoForm';
import {
  personFormValuesToRequest,
  personValidationSchema,
} from '../personForm';
import { usePostPersonMutation } from 'api/oav/PersonQuery.api';
import { isBadRequestWithDetailsError } from 'utils/api/api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAppContext } from 'components/Context/AppContext';
import { CountryCode } from 'models/Phone.models';

const Buttons = (props: { isPending: boolean }) => (
  <Grid item xs={12}>
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      useFlexGap
      spacing={2}
      justifyContent="flex-end"
      sx={{ mt: 2 }}
    >
      <Link to="/personnes">
        <Button
          color="default"
          size="medium"
          sx={{ px: 2 }}
          disabled={props.isPending}
        >
          Annuler
        </Button>
      </Link>
      <Button
        size="medium"
        type="submit"
        sx={{ px: 2 }}
        disabled={props.isPending}
      >
        {props.isPending ? (
          <CircularProgress color="inherit" size={28} />
        ) : (
          'Enregistrer'
        )}
      </Button>
    </Stack>
  </Grid>
);

const PersonPage: React.FC = () => {
  document.title = `Personnes - ${appName}`;

  const initialValues: PersonFormValues = {
    birthdate: null,
    cpamId: '',
    nni: '',
    phone: { number: '', countryCode: CountryCode.FR },
    lastname: '',
    email: '',
    firstname: '',
    civility: null,
    birthOrder: '1',
    regime: '',
    landline: { number: '', countryCode: CountryCode.FR },
    address: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      postalCode: '',
      city: '',
      country: 'fr',
    },
  };

  const navigate = useNavigate();
  const { addSnackbar } = useAppContext();
  const formikRef = useRef<FormikProps<PersonFormValues>>(null);

  const handleSuccess = (person: Person) => {
    navigate(`/personnes/${person.id}`, {});
  };

  const handleError = (error: Error) => {
    console.error(error);
    if (
      isBadRequestWithDetailsError<PersonRequestPostErrors>(error) &&
      error.response?.details
    ) {
      onBadRequest(error.response.details);
    } else {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    }
  };

  const onBadRequest = (errorDetails: PersonRequestPostErrors) => {
    const addressErrors = errorDetails.address;
    const errors = { ...errorDetails, address: undefined };
    Object.entries(errors).forEach(([key, value]) => {
      formikRef.current?.setFieldError(key, value);
      formikRef.current?.setFieldTouched(key, true, false);
    });
    if (addressErrors) {
      Object.entries(addressErrors).forEach(([key, value]) => {
        formikRef.current?.setFieldError(`address.${key}`, value);
        formikRef.current?.setFieldTouched(`address.${key}`, true, false);
      });
    }
  };

  const mutation = usePostPersonMutation(handleSuccess, handleError);

  const handleSubmit = (values: PersonFormValues) => {
    if (!mutation.isPending) {
      const personRequest = personFormValuesToRequest(values);
      mutation.mutate(personRequest);
    }
  };

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Breadcrumbs separator={<ChevronRightIcon size="small" />}>
            <Link to="/personnes" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" variant="body2" color="inherit">
                Personnes
              </LinkMUI>
            </Link>
            <Typography color="text.primary">Nouvelle personne</Typography>
          </Breadcrumbs>
        </Stack>
      </AppBar>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={personValidationSchema}
        validateOnMount={true}
      >
        {() => (
          <Form>
            <Box
              display="flex"
              justifyContent="center"
              sx={{ pt: 4, width: '100%', maxWidth: 1200, marginX: 'auto' }}
            >
              <Stack direction="column" justifyContent="center" spacing={2}>
                <IdentityForm />
                <ContactInfoForm />
                <Buttons isPending={mutation.isPending} />
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonPage;
