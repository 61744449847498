import { Address } from './Address.models';

export interface Person {
  id?: string;
  civility?: Civility;
  lastname?: string;
  firstname?: string;
  birthdate?: Date;
  email?: string;
  phone?: string;
  landline?: string;
  regimeCode?: string;
  statusCode?: string;
  nni?: string;
  birthOrder?: number;
  cpamId?: string;
  address?: Address;
  delegatePersonCode?: string;
}

export type PersonRequest = Omit<Person, 'id'>;

export type PersonContactRequest = {
  civility?: Civility;
  lastname: string;
  firstname: string;
  phone?: string;
  landline?: string;
  email?: string;
};

export type PersonRequestPostErrors = {
  civility?: string;
  gender?: string;
  lastname?: string;
  firstname?: string;
  birthdate?: string;
  email?: string;
  phone?: string;
  landline?: string;
  regimeCode?: string;
  nni?: string;
  birthOrder?: string;
  cpamId?: string;
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    postalCode?: string;
    city?: string;
    country?: string;
  };
};

export type PersonRequestPutErrors = PersonRequestPostErrors;

export interface PersonSearchCriteriaRequest {
  birthdate: Date | null;
  lastname: string;
  firstname: string;
  email: string;
}

export enum Civility {
  'M' = 'M',
  'Mme' = 'Mme',
}

export const civilityLabels: { [key in Civility]: { label: string } } = {
  [Civility.M]: { label: 'Monsieur' },
  [Civility.Mme]: { label: 'Madame' },
};
