import Grid from "@mui/material/Grid";
import { PromotionCard } from 'components/PromotionCard/PromotionCard';
import React from "react";
import { Promotion } from 'models/referentiels/Promotion.model';


export type PromotionListProps = {
  promotions: Promotion[];
  selectedPromotions?: string[];
  onPromotionSelected?: (promotionId: string, selected: boolean) => void;
};

export const PromotionList: React.FC<PromotionListProps> = (props: PromotionListProps) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {
          props.promotions.map((promotion, index) => (
            <Grid key={index} item xs={props.promotions.length === 1 ? 12 : 6}>
              <PromotionCard
                promotion={promotion}
                checked={props.selectedPromotions && props.selectedPromotions.includes(promotion.id)}
                onPromotionSelected={props.onPromotionSelected}
              />
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  );
}

