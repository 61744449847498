import React from 'react';
import { Alert, Grid } from '@mui/material';
import { useUserContext } from 'components/Context/UserContext';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';

const AccountCompanyPage: React.FC = () => {
  const userContext = useUserContext();

  return (
    <>
      {userContext.data.agency ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FieldReadOnly
              label={'Réseau'}
              value={userContext.data?.agency?.network?.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldReadOnly
              label={'Agence'}
              value={userContext.data?.agency?.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldReadOnly
              label={'Raison Sociale'}
              value={userContext.data?.agency?.companyName}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldReadOnly
              label={'Adresse ligne 1'}
              value={userContext.data?.agency?.addressLine1}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldReadOnly
              label={'Adresse ligne 2'}
              value={userContext.data?.agency?.addressLine2}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldReadOnly
              label={'Adresse ligne 3'}
              value={userContext.data?.agency?.addressLine3}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldReadOnly
              label={'Code postal'}
              value={userContext.data?.agency?.postalCode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldReadOnly
              label={'Ville'}
              value={userContext.data?.agency?.city}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldReadOnly
              label={'Site web'}
              value={userContext.data?.agency?.url}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldReadOnly
              label={'SIRET'}
              value={userContext.data?.agency?.siret}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldReadOnly
              label={'Orias'}
              value={userContext.data?.agency?.orias}
            />
          </Grid>
        </Grid>
      ) : (
        <Alert variant="outlined" severity="warning">
          L&apos;utilisateur n&apos;est rattaché à aucune entreprise existant
          dans Clariss !
        </Alert>
      )}
    </>
  );
};

export default AccountCompanyPage;
