import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { queryClient } from 'config';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useDebounce } from 'use-debounce';

import Stack from '@mui/material/Stack';

import { DialogBeneficiaries } from 'components/Dialog/DialogBeneficiaries';
import { ButtonBeneficiaries } from 'components/Button/ButtonBeneficiaries';
import DatePicker from 'components/DatePicker/DatePicker';
import { useAppContext } from 'components/Context/AppContext';

import { updateSubscription } from 'api/oav/Subscription.api';
import { ApiResponse } from 'models/api.model';
import {
  Subscription,
  SubscriptionRequest,
  SubscriptionRequestErrors,
} from 'models/oav/Subscription.models';
import { Project } from 'models/oav/Project.models';
import { Offer } from 'models/referentiels/Offer.model';

import {
  dateStartDayMax,
  dateStartDayMin,
  getYupFieldDateStart,
} from 'utils/validation/yupSubscription';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAccessToken } from 'utils/api/api';
import { messageFieldRequired } from 'utils/validation/message';
import { RestError } from 'errors/RestError';
import { useTheme } from '@mui/material/styles';
import Select from 'components/Select/Select/Select';
import MenuItem from '@mui/material/MenuItem';

export type FormSubscriptionProps = {
  disabled?: boolean;
  project: Project;
  subscription: Subscription;
  offer?: Offer;
  onChange?: (subscription: Subscription) => void;
};

const FormSubscription = (props: FormSubscriptionProps) => {
  const theme = useTheme();
  const accessToken = useAccessToken();
  const { addSnackbar } = useAppContext();

  // Form
  const formikRef = useRef<FormikProps<any>>(null);
  const validationSchema = Yup.object().shape({
    dateStart: getYupFieldDateStart().required(messageFieldRequired),
    beneficiariesId: Yup.array().of(Yup.string().required()),
    commissionPercentage: props.project.indirect
      ? Yup.number().required()
      : Yup.number().notRequired(),
  });

  const [dialogBeneficiariesOpened, setDialogBeneficiariesOpened] =
    useState<boolean>(false);
  const [dialogBeneficiariesId, setDialogBeneficiariesId] = useState<string[]>(
    [],
  );

  const getInitialValues = () => {
    return {
      dateStart: props.subscription?.dateStart,
      beneficiariesId: props.subscription?.beneficiaries?.map(_ => _.id),
      commissionPercentage: props.subscription?.commissionPercentage,
    };
  };

  const mutationSubscription = useMutation({
    mutationFn: (payload: SubscriptionRequest) => {
      return updateSubscription(
        accessToken,
        props.project.id,
        props.subscription!.id,
        payload,
      );
    },
    onSuccess: (response: Subscription) => {
      // PUT on subscription may change the project step, invalidate this projet to trigger re-fetch (using exact to only trigger GET projet and avoid all subresources re-fetch)
      queryClient.invalidateQueries({
        queryKey: ['projects', props.project.id],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ['projects', props.project.id, 'subscription', response.id],
      });
      formikRef.current?.resetForm();
    },
    onError: (error: Error) => {
      if (error instanceof RestError && error.status === 400) {
        const e = (error.response as ApiResponse<SubscriptionRequestErrors>)
          .details;
        if (e) onBadRequest(e);
      } else addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  const onBadRequest = (errors: SubscriptionRequestErrors) => {
    formikRef.current?.setFieldError('dateStart', errors.dateStart);

    const beneficiariesError = formikRef.current?.values.beneficiariesId?.map(
      (_: string, idx: number) => errors[`beneficiariesId[${idx}]`],
    );
    formikRef.current?.setFieldError(
      'beneficiariesId',
      beneficiariesError as any,
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={values => {
        let benefs = [props.project.subscriber];
        if (
          props.project.partner &&
          values.beneficiariesId.some(
            (_: string) => _ === props.project.partner?.id,
          )
        )
          benefs.push(props.project.partner);
        if (props.project.children)
          benefs.push(
            ...props.project.children.filter(_ =>
              new Set(values.beneficiariesId).has(_.id),
            ),
          );

        props.onChange?.({
          ...props.subscription,
          dateStart: values.dateStart,
          beneficiaries: benefs,
          commissionPercentage: values.commissionPercentage,
        });

        mutationSubscription.mutate({
          offerCode: props.subscription.offerCode,
          dateStart: values.dateStart,
          beneficiariesId: values.beneficiariesId,
          commissionPercentage: values.commissionPercentage,
        });
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => {
        const [debouncedDateStart] = useDebounce(values.dateStart, 300);

        useEffect(() => {
          setDialogBeneficiariesId(values.beneficiariesId || []);
        }, [dialogBeneficiariesOpened, values.beneficiariesId]);

        useEffect(() => {
          if (
            Object.entries(touched).length > 0 &&
            Object.values(touched).every(_ => _)
          ) {
            handleSubmit();
          }
        }, [
          values.beneficiariesId,
          debouncedDateStart,
          values.commissionPercentage,
        ]);
        return (
          <Form>
            <Stack
              spacing={{ lg: 4, xs: 2 }}
              direction={{ lg: 'row', md: 'column' }}
            >
              <DatePicker
                disabled={props.disabled}
                label="Début du contrat"
                format="DD/MM/YYYY"
                value={values.dateStart}
                minDate={dayjs().startOf('day').add(dateStartDayMin, 'day')}
                maxDate={dayjs().startOf('day').add(dateStartDayMax, 'day')}
                onChange={v => {
                  setFieldValue('dateStart', v);
                  setFieldTouched('dateStart');
                }}
                slotProps={{
                  textField: {
                    sx: {
                      '.MuiInputBase-root': {
                        boxShadow:
                          '0 0 5px ' +
                          theme.palette.primary.dark +
                          ' !important',
                      },
                    },
                    disabled: props.disabled,
                    fullWidth: true,
                    required: true,
                    onBlur: () => setFieldTouched('dateStart'),
                    error: touched.dateStart && Boolean(errors.dateStart),
                    helperText: touched.dateStart &&
                      Boolean(errors.dateStart) && <>{errors.dateStart}</>,
                  },
                }}
              />

              <ButtonBeneficiaries
                project={props.project}
                fullWidth
                beneficiariesLength={dialogBeneficiariesId.length}
                onClick={() => setDialogBeneficiariesOpened(true)}
                sx={{
                  boxShadow:
                    '0 0 5px ' + theme.palette.primary.dark + ' !important',
                }}
              />
              {props.project.indirect && (
                <Select
                  fullWidth
                  onChange={v => {
                    setFieldValue('commissionPercentage', v.target.value);
                    setFieldTouched('commissionPercentage');
                  }}
                  label="Commission"
                  value={values.commissionPercentage}
                  sx={{ boxShadow: '0 0 5px ' + theme.palette.primary.dark + ' !important' }}
                >
                  {props.offer &&
                    props.offer.commissionPercentages &&
                    props.offer?.commissionPercentages.map(
                      commissionPercentage => (
                        <MenuItem
                          key={commissionPercentage}
                          value={commissionPercentage}
                        >
                          {commissionPercentage}%
                        </MenuItem>
                      ),
                    )}
                </Select>
              )}
            </Stack>

            <DialogBeneficiaries
              project={props.project}
              offer={props.offer}
              beneficiaryIds={dialogBeneficiariesId}
              onClose={() => setDialogBeneficiariesOpened(false)}
              onValidate={() => {
                setFieldValue('beneficiariesId', dialogBeneficiariesId);
                setFieldTouched('beneficiariesId');
                setDialogBeneficiariesOpened(false);
              }}
              onClick={
                props.disabled
                  ? undefined
                  : id => {
                      const benef = [...dialogBeneficiariesId];
                      if (benef.includes(id)) {
                        const index = benef.indexOf(id);
                        if (index > -1) benef.splice(index, 1);
                      } else {
                        benef.push(id);
                      }
                      setDialogBeneficiariesId(benef);
                    }
              }
              open={dialogBeneficiariesOpened}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormSubscription;
