export interface UserInfo {
  name: string;
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
  codeApporteur?: string;
  email: string;
  phone_number?: string;
  title?: string;
  parentUserId?: string;
  pictureUri?: string;
  agencyId?: string;
  resource_access: {
    oav: {
      roles: UserRole[];
    };
  };
}

export interface Network {
  id: string;
  name: string;
}

export interface Agency {
  id: string;
  name: string;
  companyName: string;
  preferred_username?: string;
  slogan?: string;
  orias?: string;
  siret?: string;
  url?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  network?: Network;
  contactPhone?: string;
  contactEmail?: string;
}

export enum UserRole {
  // ROLES
  USER = 'oav-user',
  MANAGER = 'oav-manager',
  ADMIN = 'oav-admin',

  // PERMISSIONS
  ALL_PROJECTS_ACCESS = 'oav-all-projects-access',
  PERMISSION_MODE_DIRECT = 'oav-permission-mode-direct',
  PERMISSION_MODE_INDIRECT = 'oav-permission-mode-indirect',
}
