import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from 'components/TextField/TextField';
import DatePicker from 'components/DatePicker/DatePicker';
import {
  ageAdultMax,
  ageChildMin,
  birthOrderMax,
  birthOrderMin,
  cpamIdSize,
  getDateFromAge,
  nameMaxChar,
  nameMinChar,
} from 'utils/validation/yupPerson';
import { PersonFormValues } from 'models/Form.model';
import { Civility, civilityLabels } from 'models/oav/Person.models';
import { NNITextField } from 'components/TextField/formatted/NNITextField';
import FormCard from 'content/form/FormCard';
import { PersonIcon } from 'components/Icon/PersonIcon';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';
import { AutocompleteRegime } from 'content/regime/AutocompleteRegime';

const IdentityForm = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<PersonFormValues>();

  return (
    <FormCard icon={PersonIcon} label="Identité">
      <Grid
        container
        spacing={{
          sm: 2,
          xs: 1,
        }}
      >
        <Grid item xs={12}>
          <ToggleButtonGroupSplit
            value={values.civility}
            exclusive
            onChange={(e, v) => setFieldValue('civility', v)}
          >
            {Object.entries(Civility).map(([key]) => (
              <ToggleButtonSplit
                key={key}
                fullWidth
                value={key}
                color="primary"
              >
                {civilityLabels[key as Civility].label}
              </ToggleButtonSplit>
            ))}
          </ToggleButtonGroupSplit>
          {touched.civility && errors.civility && (
            <FormHelperText error>{errors.civility}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            id="lastname"
            name="lastname"
            label="Nom"
            value={values.lastname}
            error={touched.lastname && !!errors.lastname}
            helperText={touched.lastname && errors.lastname}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              minLength: nameMinChar,
              maxLength: nameMaxChar,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            id="firstname"
            name="firstname"
            label="Prénom"
            value={values.firstname}
            error={touched.firstname && !!errors.firstname}
            helperText={touched.firstname && errors.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              minLength: nameMinChar,
              maxLength: nameMaxChar,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            sx={{
              width: '100%',
            }}
            label="Date de naissance"
            format="DD/MM/YYYY"
            openTo="year"
            name="birthdate"
            value={values.birthdate}
            minDate={dayjs(getDateFromAge(ageAdultMax))}
            maxDate={dayjs(getDateFromAge(ageChildMin))}
            onChange={v => setFieldValue('birthdate', v)}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
                onBlur: handleBlur,
                error: !!touched.birthdate && !!errors.birthdate,
                helperText: !!touched.birthdate && <>{errors.birthdate}</>,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteRegime
            fullWidth
            required
            id="regimeCode"
            label="Régime social"
            value={values.regime}
            error={!!touched.regime && Boolean(errors.regime)}
            helperText={touched.regime && errors.regime}
            onChange={(e, v) => setFieldValue('regime', v)}
            onBlur={() => setFieldTouched('regime')}
          />
        </Grid>
        <Grid item xs={6}>
          <NNITextField
            fullWidth
            id="nni"
            name="nni"
            label="N° Sécurité sociale"
            value={values.nni}
            error={touched.nni && !!errors.nni}
            helperText={touched.nni && errors.nni}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="cpamId"
            name="cpamId"
            label="Code caisse"
            value={values.cpamId}
            error={touched.cpamId && !!errors.cpamId}
            helperText={touched.cpamId && errors.cpamId}
            onChange={e =>
              setFieldValue('cpamId', e.target.value.replaceAll(/\D/g, ''))
            }
            onBlur={handleBlur}
            inputProps={{ minLength: cpamIdSize, maxLength: cpamIdSize }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="birthOrder"
            name="birthOrder"
            label="Rang de naissance"
            type="number"
            value={values.birthOrder}
            error={touched.birthOrder && !!errors.birthOrder}
            helperText={touched.birthOrder && errors.birthOrder}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              min: birthOrderMin,
              max: birthOrderMax,
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default IdentityForm;
