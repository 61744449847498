import { useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import FormBeneficiariesExpress, {
  FormBeneficiariesExpressRef,
  FormBeneficiariesExpressValues,
} from 'content/form/FormBeneficiariesExpress';
import CardError from 'components/Card/CardError';
import { useProjectContext } from 'components/Context/ProjectContext';
import { BeneficiaryType } from 'models/oav/Beneficiary.models';

import { projectQueries } from 'api/oav/ProjectQuery.api';
import { navigateToNextStep } from 'utils/project/project';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import { RestError } from 'errors/RestError';

const CURRENT_STEP = ProjectStep.BENEFICIARIES;

const ProjectBeneficiariesPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const navigate = useNavigate();

  const { id } = useParams();
  const refForm = useRef<FormBeneficiariesExpressRef>(null);

  if (!id) return <></>;

  const contextProject = useProjectContext();
  const projectQuery = useQuery(projectQueries.getById(id));

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError) return 'error';
    if (projectQuery.isLoading || !projectQuery.data) return 'loading';
    return 'done';
  }, [projectQuery]);

  const [loading, setLoading] = useState<boolean>(false);

  const getInitialValues = (): FormBeneficiariesExpressValues => {
    const values: FormBeneficiariesExpressValues = {};
    if (!projectQuery.data) return values;

    values.projectId = projectQuery.data.id;

    if (projectQuery.data.subscriber) {
      const _ = projectQuery.data.subscriber;
      values.subscriber = {
        beneficiaryId: _.id,
        personId: _.person.id,
        type: BeneficiaryType.SUBSCRIBER,
        postalCode: _.person.address?.postalCode,
        birthDate: _.person.birthdate,
        regimeCode: _.person.regimeCode,
        statusCode: _.person.statusCode,
      };
    }

    if (projectQuery.data.partner) {
      const _ = projectQuery.data.partner;
      values.partner = {
        beneficiaryId: _.id,
        personId: _.person.id,
        type: BeneficiaryType.PARTNER,
        birthDate: _.person.birthdate,
        regimeCode: _.person.regimeCode,
      };
    }

    if (projectQuery.data.children && projectQuery.data.children.length > 0) {
      values.children = [];
      for (const _ of projectQuery.data.children) {
        values.children.push({
          beneficiaryId: _.id,
          personId: _.person.id,
          type: BeneficiaryType.CHILD,
          birthDate: _.person.birthdate,
          regimeCode: _.person.regimeCode,
        });
      }
    }

    return values;
  };

  const submit = async () => {
    setLoading(true);

    refForm.current
      ?.submitForm()
      .then(project => {
        if (!project) return;
        navigateToNextStep(CURRENT_STEP, id, navigate);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container justifyContent="center" alignItems="center" gap={2}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Bénéficiaires
          <Chip
            sx={{
              ml: 1,
            }}
            label={
              (contextProject.dataCurrent.project?.children?.length || 0) +
              (contextProject.dataCurrent.project?.subscriber ? 1 : 0) +
              (contextProject.dataCurrent.project?.partner ? 1 : 0)
            }
          />
        </Typography>
      </Grid>
      {contextStatus === 'done' && projectQuery.data ? (
        <>
          <Grid item xs={12}>
            <FormBeneficiariesExpress
              ref={refForm}
              initialValues={getInitialValues()}
              maxBeneficiaries={9}
              onChangeValues={_ => {
                contextProject.setDataCurrent(prev => {
                  if (!prev.project) return prev;

                  const subscriber = {
                    ...prev.project.subscriber,
                    person: {
                      ...prev.project.subscriber.person,
                      birthdate: _.subscriber?.birthDate as Date,
                      regimeCode: _.subscriber?.regimeCode,
                    },
                  };

                  const partner = _.partner && {
                    ...prev.project.partner,
                    type: BeneficiaryType.PARTNER,
                    person: {
                      ...prev.project.partner?.person,
                      birthdate: _.partner?.birthDate as Date,
                      regimeCode: _.partner?.regimeCode,
                    },
                  };

                  const children =
                    _.children &&
                    [...(_.children || [])].map((c, index) => {
                      const prevChild = prev.project?.children && {
                        ...prev.project.children[index],
                      };
                      return {
                        prevChild,
                        type: BeneficiaryType.CHILD,
                        person: {
                          prevChild,
                          birthdate: c?.birthDate as Date,
                          regimeCode: c?.regimeCode,
                        },
                      };
                    });

                  // Here only the express data are updated.
                  return {
                    ...prev,
                    project: {
                      ...prev.project,
                      subscriber: subscriber,
                      partner: partner,
                      children: children,
                    },
                  };
                });
              }}
            />
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              disabled={loading}
              onClick={() => {
                submit();
              }}
              sx={{ px: 4 }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Valider'
              )}
            </Button>
          </Grid>
        </>
      ) : contextStatus === 'error' ? (
        <Grid
          item
          xs={12}
          sx={{
            height: 200,
          }}
        >
          <CardError
            status={
              projectQuery.error instanceof RestError
                ? projectQuery.error.status
                : undefined
            }
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ProjectBeneficiariesPage;
