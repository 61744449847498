import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from 'components/TextField/TextField';
import { useQuery } from '@tanstack/react-query';
import { cityQueries } from 'api/external/CityQuery.api';
import { City } from 'models/external/City.model';
import { TextFieldProps } from '@mui/material/TextField';

export type TextFieldCityProps = Partial<TextFieldProps> & {
  postalCode?: string;
  onValueChange?: (value: string) => void;
};

export const TextFieldCity = (props: TextFieldCityProps) => {
  const POSTAL_CODE_SIZE = 5;
  const cities: City[] | undefined =
    props.postalCode != null && props.postalCode.length == POSTAL_CODE_SIZE
      ? useQuery(cityQueries.getByPostalCode(props.postalCode)).data
      : undefined;

  return (
    <Autocomplete
      freeSolo
      onInputChange={(_, v) => {
        if (props.onValueChange == null) {
          return;
        }
        props.onValueChange(v);
      }}
      disableClearable
      defaultValue={props.value as string}
      options={cities != null ? cities.map(option => option.name) : []}
      sx={{ '.MuiInputBase-root': { pt: '13px' } }}
      renderInput={params => <TextField {...props} {...params} />}
    />
  );
};
