import { ReactNode } from 'react';

export type Phone = {
  number: string;
  countryCode: CountryCode;
};

export type PhoneCountry = {
  leadingZero: boolean;
  dialCode: string;
  icon: ReactNode;
  label: string;
  code: CountryCode;
  patternRegex: RegExp;
};

export enum CountryCode {
  DE = 'de', // Allemagne
  AD = 'ad', // Andorra
  BE = 'be', // Belgium
  CH = 'ch', // Switzerland
  ES = 'es', // Spain
  FR = 'fr', // France
  IT = 'it', // Italy
  LU = 'lu', // Luxembourg
  MC = 'mc', // Monaco
}
