import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useProjectContext } from 'components/Context/ProjectContext';
import TextField from 'components/TextField/TextField';

import { useAppContext } from 'components/Context/AppContext';
import { Icon } from 'components/Icon';
import { CloseIcon } from 'components/Icon/CloseIcon';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import CardSubscription from 'content/subscription/CardSubscription';
import GridSubscriptionSolutions from 'content/subscription/GridSubscriptionSolutions';

import { projectQueries } from 'api/oav/ProjectQuery.api';
import {
  commercialPropositionMutations,
  commercialPropositionQueries,
} from 'api/oav/CommercialPropositionQuery.api';

import {
  StaticDocumentTypeCodes,
  staticDocumentTypeData,
} from 'models/referentiels/Document.models';
import { CommercialProposition } from 'models/oav/CommercialProposition.models';

import {
  getDefaultErrorSnackBar,
  getDefaultSuccessSnackBar,
} from 'utils/snackbars/Snackbars';
import { yupEmail } from 'utils/validation/yupPerson';
import { messageFieldRequired } from 'utils/validation/message';
import {
  ContractualDocumentType,
  documentContractualTypeData,
} from 'models/oav/ContractualDocument.models';
import CardSelect from 'components/Card/CardSelect';
import { Card, Divider } from '@mui/material';
import dayjs from 'dayjs';

type FormValues = {
  email: string;
  documents: {
    selected: boolean;
    isStaticDocument: boolean;
    code: StaticDocumentTypeCodes | ContractualDocumentType;
    label: string;
    icon?: string;
  }[];
};

export const validationSchema = Yup.object().shape({
  email: yupEmail.required(messageFieldRequired),
});

export const CommercialPropositionDialog = () => {
  const { id: projectId } = useParams();
  const validatedWithMail = useRef(false);

  if (!projectId) return <></>;

  const theme = useTheme();

  const projectQueryOptions = projectQueries.getById(projectId);
  const projectQuery = useQuery(projectQueryOptions);
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(projectId));
  const documentTypesQuery = useQuery(
    commercialPropositionQueries.getDocumentTypes(projectId),
  );

  const { closeCommercialPropositionDialog } = useProjectContext();

  const { addSnackbar } = useAppContext();
  const queryClient = useQueryClient();

  const project = projectQuery.data;

  const subscription = project?.subscriptions?.[0];

  const offerCode = subscription?.offerCode || '';

  const offer = offersMapQuery.data?.[offerCode];

  const handleSuccess = async (data: CommercialProposition | Blob) => {
    if (data instanceof Blob) {
      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download =
        project?.subscriber?.person?.firstname +
        ' ' +
        project?.subscriber?.person?.lastname +
        ' - ' +
        dayjs().format('YYYY-MM-DD HH[h]mm') +
        '.zip';
      link.click();
      setTimeout(() => link.remove(), 500);
    } else {
      addSnackbar(
        getDefaultSuccessSnackBar('Le projet a été envoyé avec succès.'),
      );
    }
    try {
      await queryClient.invalidateQueries({
        queryKey: projectQueryOptions.queryKey,
      });
    } catch (e) {
      // Do not throw error if the invalidate fails
      console.error(e);
    }

    closeCommercialPropositionDialog();
  };

  const handleError = () => {
    addSnackbar(
      getDefaultErrorSnackBar(
        'Une erreur est survenue durant la génération du projet.',
      ),
    );
  };

  const { isPending: isMutationPending, mutate } =
    commercialPropositionMutations.useCreate(handleSuccess, handleError);

  const title = 'Édition de projet';

  const initialValues = useMemo<FormValues>(() => {
    const documents: FormValues['documents'] =
      documentTypesQuery.data?.contractualDocuments?.map(_ => ({
        selected: true,
        isStaticDocument: false,
        code: _.code,
        label: documentContractualTypeData[_.code].label,
        icon: documentContractualTypeData[_.code].icon,
      })) ?? [];
    documentTypesQuery.data?.staticDocuments
      ?.map(_ => ({
        selected: false,
        isStaticDocument: true,
        code: _.code,
        label: _.label,
        icon: staticDocumentTypeData[_.code]?.icon,
      }))
      ?.forEach(_ => documents.push(_));

    return {
      email: project?.subscriber?.person?.email ?? '',
      documents,
    };
  }, [project, documentTypesQuery.data]);

  const formikRef = useRef<FormikProps<FormValues>>(null);

  if (offersMapQuery.error || projectQuery.error || documentTypesQuery.error) {
    return (
      <ErrorDialog onClose={closeCommercialPropositionDialog} title={title} />
    );
  }

  if (!project || !subscription || !offer || !documentTypesQuery.data) {
    return <LoadingDialog title={title} />;
  }

  const handleSubmitWithEmail = async () => {
    validatedWithMail.current = true;
    try {
      mutate({
        projectId: project.id,
        payload: {
          sendEmail: true,
          email: formikRef!.current!.values.email,
          staticDocuments: formikRef!
            .current!.values.documents.filter(
              _ => _.isStaticDocument && _.selected,
            )
            .map(_ => _.code as StaticDocumentTypeCodes),
        },
        accept: 'application/json',
      });
    } catch (e) {
      handleError();
    }
  };

  const handleSubmitWithoutEmail = async () => {
    validatedWithMail.current = false;
    try {
      mutate({
        projectId: project.id,
        payload: {
          sendEmail: false,

          staticDocuments: formikRef!
            .current!.values.documents.filter(
              _ => _.isStaticDocument && _.selected,
            )
            .map(_ => _.code as StaticDocumentTypeCodes),
        },
        accept: 'application/zip',
      });
    } catch (e) {
      handleError();
    }
  };

  const loadingWithMail = isMutationPending && validatedWithMail.current;
  const loadingWithoutMail = isMutationPending && !loadingWithMail;

  return (
    <>
      <DialogTitle>
        <Typography variant="h2">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={closeCommercialPropositionDialog}
          disabled={isMutationPending}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            ':hover': {
              backgroundColor: 'rgba(250, 250, 250, 0.04)',
            },
          }}
        >
          <Icon color={theme.palette.text.primary}>
            <CloseIcon />
          </Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik<FormValues>
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmitWithEmail}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardSubscription
                    divider
                    project={project}
                    subscription={subscription}
                    offer={offer}
                  >
                    <GridSubscriptionSolutions
                      project={project}
                      selectedSolutionId={subscription.solutionId}
                      displayOnlySelected={subscription.solutionId != null}
                      subscription={subscription}
                      offer={offer}
                    />
                  </CardSubscription>
                </Grid>

                <Grid item xs={12}>
                  <Card color="darken" sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          <b>Envoyez par mail</b> ou&nbsp;
                          <b>téléchargez</b> votre projet et les documents
                          d&#39;informations légales suivants :
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {values.documents.map((_, index) => (
                            <Grid
                              key={_.code}
                              item
                              md={6}
                              xs={12}
                              sx={{ height: '100%' }}
                            >
                              <CardSelect
                                selected={_.selected}
                                disabled={!_.isStaticDocument}
                                onSelect={selected =>
                                  setFieldValue(
                                    `documents[${index}].selected`,
                                    selected,
                                  )
                                }
                              >
                                <Typography fontWeight="bold">
                                  {`${_.icon ? _.icon + ' ' : ''}${_.label}`}
                                </Typography>
                              </CardSelect>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email de votre client"
                    value={values.email}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{ minLength: 1, maxLength: 100 }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              onClick={closeCommercialPropositionDialog}
              disabled={isMutationPending}
              sx={{
                px: 4,
              }}
            >
              Annuler
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={handleSubmitWithoutEmail}
              disabled={loadingWithMail || loadingWithoutMail}
              sx={{
                px: 4,
              }}
            >
              {loadingWithoutMail ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Télécharger'
              )}
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              disabled={loadingWithMail || loadingWithoutMail}
              onClick={() => formikRef.current?.submitForm()}
              sx={{
                px: 4,
              }}
            >
              {loadingWithMail ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Envoyer par mail'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const LoadingDialog = ({ title }: { title: string }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="100px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
    </DialogContent>
  </>
);

const ErrorDialog = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <ErrorMessage message="Une erreur est survenue" />
    </DialogContent>
    <DialogActions>
      <Button color="default" onClick={onClose}>
        Fermer
      </Button>
    </DialogActions>
  </>
);
