import { queryOptions } from '@tanstack/react-query';
import { fetchCityByPostalCode } from 'api/external/laposte.api';

export const cityQueries = {
  getByPostalCode: (postalCode: string) =>
    fetchCityByPostalCodeOptions(postalCode),
};

export const fetchCityByPostalCodeOptions = (postalCode: string) => {
  return queryOptions({
    queryKey: ['city', postalCode],
    queryFn: () => {
      return fetchCityByPostalCode(postalCode);
    },
  });
};
