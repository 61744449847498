import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppContext } from 'components/Context/AppContext';
import { CompanyCircleIllustrationIcon } from 'components/Icon/CompanyCircleIllustrationIcon';
import { PersonCircleIllustrationIcon } from 'components/Icon/PersonCircleIllustrationIcon';
import { Link, useNavigate } from 'react-router-dom';
import { ProjectTypeButton } from 'components/Button/ButtonProjectType/ButtonProjectType';
import { getSigninToHennerQueryOptions } from 'api/authentication/AuthenticationQuery.api';
import { useQueryClient } from '@tanstack/react-query';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import AppBar from 'components/AppBar';
import { useRole } from 'utils/hooks/useRole';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { Link as LinkMUI } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserRole } from 'models/UserInfo.model';

export const ProjectTypePage: React.FC = () => {
  const app = useAppContext();
  const theme = useTheme();
  const navigate = useNavigate();

  const authToHennerQueryOptions = getSigninToHennerQueryOptions();
  const queryClient = useQueryClient();
  const [authLoading, setAuthLoading] = useState(false);

  const [indirect, setIndirect] = React.useState(false);

  const hasPermissionModeDirect = useRole(UserRole.PERMISSION_MODE_DIRECT);
  const hasPermissionModeIndirect = useRole(UserRole.PERMISSION_MODE_INDIRECT);
  const hasPermissionModeAll =
    hasPermissionModeDirect && hasPermissionModeIndirect;

  useEffect(() => {
    app.setData({
      ...app.data,
      sidebarMinified: true,
    });
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <AppBar>
        <Stack
          flexDirection={{ md: 'row', xs: 'column' }}
          gap={2}
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Breadcrumbs separator={<ChevronRightIcon size="small" />}>
            <Link to="/projets" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" color="inherit">
                Projets
              </LinkMUI>
            </Link>
            <Typography>Nouveau Projet</Typography>
          </Breadcrumbs>

          {hasPermissionModeAll && (
            <Box
              sx={{ mx: { md: 0, xs: 'auto' }, mb: 5, pl: { sm: 0, xs: 4 } }}
            >
              <ToggleButtonGroupSplit
                exclusive
                value={indirect}
                onChange={(_, v) => {
                  if (v == null) return;
                  setIndirect(v);
                }}
                orientation={isMobile ? 'vertical' : 'horizontal'}
              >
                <ToggleButtonSplit color="primary" value={false}>
                  Vente directe
                </ToggleButtonSplit>
                <ToggleButtonSplit color="primary" value={true}>
                  Vente indirecte
                </ToggleButtonSplit>
              </ToggleButtonGroupSplit>
            </Box>
          )}
        </Stack>
      </AppBar>

      <Stack
        gap={2}
        justifyContent="center"
        sx={{ mx: 'auto', height: '100%' }}
      >
        <Typography variant="h2" textAlign={{ md: 'left', xs: 'center' }}>
          Qui souhaitez-vous accompagner ?
        </Typography>
        <Stack
          gap={{ lg: 10, xs: 3 }}
          direction={{ lg: 'row', xs: 'column' }}
          alignItems="center"
        >
          <ProjectTypeButton
            title="Un particulier ou un TNS"
            icon={<PersonCircleIllustrationIcon />}
            onClick={() =>
              navigate({
                pathname: '/projets/nouveau',
                search:
                  hasPermissionModeAll && indirect ? '?indirect' : undefined,
              })
            }
          />
          <ProjectTypeButton
            title="Une entreprise"
            info="Accédez à nos offres collectives dans GSMC Pro en cliquant sur ce bouton"
            icon={<CompanyCircleIllustrationIcon />}
            loading={authLoading}
            onClick={async () => {
              if (authLoading) {
                return;
              }
              try {
                setAuthLoading(true);
                const redirectUri = await queryClient.fetchQuery(
                  authToHennerQueryOptions,
                );
                window.open(redirectUri, '_blank');
              } catch (err) {
                window.open(
                  'https://www.mutuelle-gsmc.fr/courtier/oav/devis',
                  '_blank',
                );
              }
              setAuthLoading(false);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};
