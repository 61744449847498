import {
  CommercialProposition,
  CommercialPropositionDocumentTypes,
  CommercialPropositionRequest,
} from 'models/oav/CommercialProposition.models';
import {
  ContractualDocument,
  ContractualDocumentDownload,
} from 'models/oav/ContractualDocument.models';
import { getRequest, putRequest } from 'utils/queries/queries.utils';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const createCommercialProposition = async (
  accessToken: string,
  projectId: string,
  payload: CommercialPropositionRequest,
  accept:
    | 'application/json'
    | 'application/pdf'
    | 'application/zip' = 'application/json',
): Promise<CommercialProposition | Blob> => {
  const url = `${baseUrl}/${projectId}/commercial-proposition`;

  const response = await putRequest(
    url,
    payload,
    accessToken,
    {
      'Content-Type': 'application/json',
      Accept: accept,
    },
    accept === 'application/json' ? 'json' : 'blob',
  );

  if (response instanceof Blob) {
    return response;
  }

  return mapCommercialProposition(response);
};

export const getCommercialProposition = async (
  accessToken: string,
  projectId: string,
) => {
  return mapCommercialProposition(
    await getRequest(
      `${baseUrl}/${projectId}/commercial-proposition`,
      accessToken,
    ),
  );
};

export const getContractualDocument = async (
  accessToken: string,
  projectId: string,
  documentId: string,
) => {
  return mapContractualDocumentDownload(
    await getRequest(
      `${baseUrl}/${projectId}/commercial-proposition/contractual-documents/${documentId}`,
      accessToken,
    ),
  );
};

export const fetchCommercialPropositionDocumentTypes = async (
  accessToken: string,
  projectId: string,
): Promise<CommercialPropositionDocumentTypes> => {
  const url = `${baseUrl}/${projectId}/commercial-proposition/document-types`;

  return getRequest(url, accessToken);
};

const mapCommercialProposition = (response: any): CommercialProposition => ({
  ...response,
  signatureSentAt:
    response.signatureSentAt && new Date(response.signatureSentAt),
  signatureExpireAt:
    response.signatureExpireAt && new Date(response.signatureExpireAt),
  contractualDocuments: response.contractualDocuments.map((_: any) =>
    mapContractualDocument(_),
  ),
});

const mapContractualDocument = (response: any): ContractualDocument => ({
  ...response,
});

const mapContractualDocumentDownload = (
  response: any,
): ContractualDocumentDownload => ({
  ...response,
  ...mapContractualDocument(response),
});
