import { PageResponse } from 'models/api.model';
import { Pagination } from 'models/Page.models';
import {
  Project,
  ProjectRequestPost,
  ProjectSearchCriteriaRequest,
  ProjectSignatureRequest,
} from 'models/oav/Project.models';
import { buildParams } from 'utils/api/params';
import { Offer } from 'models/referentiels/Offer.model';
import { formatDate } from 'utils/api/api';
import { Subscription } from 'models/oav/Subscription.models';
import { captureError } from '../../sentry/SentryConfig';
import { RestError } from 'errors/RestError';
import { mapSubscription } from 'api/oav/Subscription.api';
import { mapBeneficiary } from 'api/oav/Beneficiary.api';
import {
  getRequest,
  postRequest,
  putRequest,
} from 'utils/queries/queries.utils';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const fetchProjectsList = async (
  accessToken: string,
  pagination?: Pagination,
  criteria?: ProjectSearchCriteriaRequest,
  sort?: string[],
): Promise<PageResponse<Project>> => {
  const params = new URLSearchParams();
  if (pagination)
    buildParams(pagination, 'pagination')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (criteria) {
    const criteriaParams = {
      ...criteria,
      fromDate: formatDate(criteria.fromDate),
      toDate: formatDate(criteria.toDate),
    };
    buildParams(criteriaParams, 'criterias')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  }

  if (sort) params.append('sort', sort.join(','));
  const url: string = params.size > 0 ? `${baseUrl}?${params}` : baseUrl;

  return await getRequest(url, accessToken);
};

export const mapProject = (resp: any) => ({
  ...resp,
  subscriptions: mapSubscriptions(resp.subscriptions),
  subscriber: mapBeneficiary(resp.subscriber),
  partner: resp.partner ? mapBeneficiary(resp.partner) : undefined,
  children: resp.children ? resp.children.map(mapBeneficiary) : undefined,
});

export const fetchProjectById = async (
  accessToken: string,
  id: string,
): Promise<Project> => {
  const url: string = `${baseUrl}/${id}`;

  return await getRequest(url, accessToken);
};

export const mapSubscriptions = (
  subscriptions?: any[],
): Subscription[] | undefined => {
  return subscriptions?.map(mapSubscription);
};

export const fetchProjectOffers = async (
  accessToken: string,
  id: string,
): Promise<PageResponse<Offer>> => {
  const url: string = `${baseUrl}/${id}/offers`;

  return await getRequest(url, accessToken);
};

export const createProject = async (
  accessToken: string,
  payload: ProjectRequestPost,
): Promise<Project> => {
  const url = baseUrl;
  const body = {
    ...payload,
    dateStart: formatDate(payload.dateStart),
    subscriber: {
      ...payload.subscriber,
      birthdate: formatDate(payload.subscriber.birthdate),
    },
    partner: payload.partner
      ? {
          ...payload.partner,
          birthdate: formatDate(payload.partner.birthdate),
        }
      : undefined,
    children: payload.children
      ? payload.children.map(c => ({
          ...c,
          birthdate: formatDate(c.birthdate),
        }))
      : undefined,
  };

  return postRequest(url, body, accessToken);
};

export const cancelSignatureLink = async (
  accessToken: string,
  projectId: string,
) => {
  const url: string = `${baseUrl}/${projectId}/signature/cancel`;

  return mapProject(await postRequest(url, {}, accessToken));
};

export const cancelProject = async (accessToken: string, projectId: string) => {
  const url: string = `${baseUrl}/${projectId}/cancel`;

  return mapProject(await putRequest(url, {}, accessToken));
};

export const requestDigitalSignature = async (
  id: string,
  payload: ProjectSignatureRequest,
  accessToken: string,
) => {
  const url: string = `${baseUrl}/${id}/signature`;

  return postRequest(url, payload, accessToken);
};

export const signProjectManually = async (
  accessToken: string,
  projectId: string,
  commercialProposition: File,
): Promise<Project> => {
  const url = `${baseUrl}/${projectId}/signature/manual`;

  const formData = new FormData();
  formData.append('commercialProposition', commercialProposition);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  if (response.status / 100 >= 4) {
    const error = await response.json();
    captureError('project signature manual', response.status, {
      response: error,
      request: formData,
    });

    throw new RestError(response.status, url, 'POST', formData, error);
  }

  return await response.json();
};
