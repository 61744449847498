import { buildParams } from 'utils/api/params';
import { getRequest } from 'utils/queries/queries.utils';
import { PersonStatus, PersonStatusFilters } from 'models/referentiels/PersonStatus.models';
import { Pagination } from 'models/Page.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/referentiels/person-status`;

export const fetchPersonStatusPage = async (
  accessToken: string,
  pagination?: Pagination,
  filters?: PersonStatusFilters,
  sort?: string[],
): Promise<PersonStatus[]> => {
  const params = new URLSearchParams();
  if (pagination)
    buildParams(pagination, 'pagination')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (filters)
    buildParams(filters, 'filters')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (sort) params.append('sort', sort.join(','));

  const url: string = params.size > 0 ? `${baseUrl}?${params}` : baseUrl;

  return await getRequest(url, accessToken);
};

export const fetchPersonStatus = async (
  accessToken: string,
  filters?: PersonStatusFilters,
  sort?: string[],
): Promise<PersonStatus[]> => {
  const params = new URLSearchParams();
  if (filters)
    buildParams(filters, 'filters')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (sort) params.append('sort', sort.join(','));

  let url: string = `${baseUrl}/all`
  url = params.size > 0 ? `${url}?${params}` : url;

  return await getRequest(url, accessToken);
};

export const fetchPersonStatusByCode = async (
  accessToken: string,
  code: string,
): Promise<PersonStatus> => {
  const url = `${baseUrl}/${code}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return await getRequest(url, accessToken);
};
