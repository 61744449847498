import {
  BankDetailsRequest,
  Subscription,
  SubscriptionAllSolution,
  SubscriptionPromotionRequest,
  SubscriptionRequest,
} from 'models/oav/Subscription.models';
import { Tarification } from 'models/referentiels/Tarification.models';
import { formatDate } from 'utils/api/api';
import { mapBeneficiary } from 'api/oav/Beneficiary.api';
import { getRequest, putRequest } from 'utils/queries/queries.utils';
import { Solution } from 'models/oav/Solution.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const mapSubscription = (subscription: any): Subscription => ({
  ...subscription,
  dateStart: subscription.dateStart && new Date(subscription.dateStart),
  beneficiaries: subscription.beneficiaries.map(mapBeneficiary),
});

export const updateSubscription = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: SubscriptionRequest,
): Promise<Subscription> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}`;
  const body = {
    ...payload,
    dateStart: formatDate(payload.dateStart),
  };
  return await putRequest(url, body, accessToken);
};

// Get tarification
export const fetchSubscriptionTarification = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<Tarification> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/tarification`;
  return await getRequest(url, accessToken);
};

// Get solution
export const fetchSubscriptionSolution = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<Solution> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/solution`;
  return await getRequest(url, accessToken);
};

// Get solutions
export const fetchSubscriptionSolutions = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<SubscriptionAllSolution> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/solutions`;
  return await getRequest(url, accessToken);
};

// Update bank details
export const updateBankDetails = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: BankDetailsRequest,
): Promise<Subscription> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/bankDetails`;
  const response = await putRequest(url, payload, accessToken);
  return mapSubscription(response);
};

export const updatePromotions = async (
  projectId: string,
  subscriptionId: string,
  payload: SubscriptionPromotionRequest,
  accessToken: string,
): Promise<Subscription> => {
  const url: string = `${baseUrl}/${projectId}/subscription/${subscriptionId}/promotions`;
  const response = await putRequest(url, payload, accessToken);
  return mapSubscription(response);
};
