import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

import TextField from 'components/TextField/TextField';

import { Regime } from 'models/referentiels/Regime.models';

import { useRegimes } from 'queries';

type AutocompleteRegimeProps = Partial<
  AutocompleteProps<string, false, true, false>
> & {
  label?: string;
  required?: boolean;
  touched?: boolean;
  error: boolean;
  helperText?: string | false | undefined;
};

export const AutocompleteRegime = (props: AutocompleteRegimeProps) => {
  const theme = useTheme();

  const { data: regimes } = useRegimes();

  const [selectedValue, setSelectedValue] = useState<Regime | undefined>(
    undefined,
  );

  useEffect(() => {
    if (regimes && props.value) {
      const foundRegime = regimes.find(regime => regime.code === props.value);
      setSelectedValue(foundRegime || undefined);
    }
  }, [regimes, props.value]);

  return (
    <>
      <Autocomplete<Regime, false, true, false>
        key={selectedValue?.code || 'default'}
        id={props.id}
        fullWidth
        disableClearable
        options={[...(regimes || [])].sort((a, b) => {
          const priorityCodes = ['0001', '0050'];
          const isPriorityA = priorityCodes.includes(a.code);
          const isPriorityB = priorityCodes.includes(b.code);

          if (isPriorityA && !isPriorityB) return -1;
          if (!isPriorityA && isPriorityB) return 1;
          return 0;
        })}
        getOptionLabel={option => {
          if (!option.acronym) return option.label;
          return option.acronym + ' - ' + option.label;
        }}
        groupBy={option =>
          ['0001', '0050'].includes(option.code) ? 'Main' : 'Autres'
        }
        value={selectedValue}
        onChange={(
          event: any,
          value: Regime | null,
          reason: AutocompleteChangeReason,
        ) => {
          setSelectedValue(value || undefined);
          props.onChange?.(
            event,
            value !== null ? value.code : '',
            reason,
            undefined,
          );
        }}
        onBlur={props.onBlur}
        filterOptions={(options, state) => {
          const input = state.inputValue.toLowerCase();

          return options.filter(
            option =>
              option.label.toLowerCase().includes(input) ||
              option.description?.toLowerCase().includes(input) ||
              option.acronym?.toLowerCase().includes(input),
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            required={props.required}
            error={props.error}
          />
        )}
        renderGroup={params => (
          <li key={params.key}>
            {params.key != '0' && <Divider />}
            {params.children}
          </li>
        )}
        renderOption={(props, option) => {
          const isSelected = selectedValue?.code === option.code;
          return (
            <li {...props} key={option.code}>
              <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                <Stack sx={{ width: '100%' }}>
                  <Typography>
                    {option.acronym && (
                      <>
                        <pre style={{ display: 'inline-flex' }}>
                          {option.acronym}
                        </pre>
                        &nbsp;-&nbsp;
                      </>
                    )}
                    {option.label}
                  </Typography>
                  {option.description && (
                    <Typography variant="caption">
                      {option.description}
                    </Typography>
                  )}
                </Stack>
                {isSelected && (
                  <CheckIcon sx={{ color: theme.palette.primary.main }} />
                )}
              </Stack>
            </li>
          );
        }}
      />

      {props.helperText && (
        <FormHelperText error>{props.helperText as string}</FormHelperText>
      )}
    </>
  );
};
