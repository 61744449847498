import {
  AndorraIcon,
  BelgiumIcon,
  FranceIcon,
  GermanyIcon,
  ItalyIcon,
  LuxembourgIcon,
  MonacoIcon,
  SpainIcon,
  SwitzerlandIcon,
} from 'components/Icon/Flags';
import { CountryCode, Phone, PhoneCountry } from 'models/Phone.models';

// Format : '0?. .. .. .. ..'
const franceRegex =
  /(0?[0-9]?)([0-9]{0,2})([0-9]{0,2})([0-9]{0,2})([0-9]{0,2})/;

// Format : '0?.... ......'
const germanyRegex = /(0?[0-9]{0,4})([0-9]{0,6})/;

// Format : '... ... ...'
const spainRegex = /([0-9]{0,3})([0-9]{0,3})([0-9]{0,3})/;

// Format : '0?.. .. .. ..'
const belgiumRegex = /(0?[0-9]{0,2})([0-9]{0,2})([0-9]{0,2})([0-9]{0,2})/;

// Format : '6?.. ... ...'
const luxembourgRegex = /(6?[0-9]{0,2})([0-9]{0,3})([0-9]{0,3})/;

// Format : '.. .. .. ..'
const monacoRegex = /([0-9]{0,2})([0-9]{0,2})([0-9]{0,2})([0-9]{0,2})/;

// Format : '. .....'
const andorraRegex = /([0-9]?)([0-9]{0,5})/;

// Format : '0?.. ... .. ..'
const switzerlandRegex = /(0?[0-9]{0,2})([0-9]{0,3})([0-9]{0,2})([0-9]{0,2})/;

// Format : '... .......'
const italyRegex = /([0-9]{0,3})([0-9]{0,7})/;

export const phoneCountriesMap: Record<CountryCode, PhoneCountry> = {
  [CountryCode.DE]: {
    leadingZero: true,
    dialCode: '49',
    icon: <GermanyIcon />,
    label: 'Allemagne',
    code: CountryCode.DE,
    patternRegex: germanyRegex,
  },
  [CountryCode.FR]: {
    leadingZero: true,
    dialCode: '33',
    icon: <FranceIcon />,
    label: 'France',
    code: CountryCode.FR,
    patternRegex: franceRegex,
  },
  [CountryCode.BE]: {
    leadingZero: true,
    dialCode: '32',
    icon: <BelgiumIcon />,
    label: 'Belgique',
    code: CountryCode.BE,
    patternRegex: belgiumRegex,
  },
  [CountryCode.IT]: {
    leadingZero: false,
    dialCode: '39',
    icon: <ItalyIcon />,
    label: 'Italie',
    code: CountryCode.IT,
    patternRegex: italyRegex,
  },
  [CountryCode.AD]: {
    leadingZero: false,
    dialCode: '376',
    icon: <AndorraIcon />,
    label: 'Andorre',
    code: CountryCode.AD,
    patternRegex: andorraRegex,
  },
  [CountryCode.ES]: {
    leadingZero: false,
    dialCode: '34',
    icon: <SpainIcon />,
    label: 'Espagne',
    code: CountryCode.ES,
    patternRegex: spainRegex,
  },
  [CountryCode.LU]: {
    leadingZero: false,
    dialCode: '352',
    icon: <LuxembourgIcon />,
    label: 'Luxembourg',
    code: CountryCode.LU,
    patternRegex: luxembourgRegex,
  },
  [CountryCode.CH]: {
    leadingZero: true,
    dialCode: '41',
    icon: <SwitzerlandIcon />,
    label: 'Suisse',
    code: CountryCode.CH,
    patternRegex: switzerlandRegex,
  },
  [CountryCode.MC]: {
    leadingZero: false,
    dialCode: '377',
    icon: <MonacoIcon />,
    label: 'Monaco',
    code: CountryCode.MC,
    patternRegex: monacoRegex,
  },
};

export const countriesByDialCode: { [key: string]: PhoneCountry | undefined } =
  {
    [phoneCountriesMap[CountryCode.DE].dialCode]:
      phoneCountriesMap[CountryCode.DE],
    [phoneCountriesMap[CountryCode.FR].dialCode]:
      phoneCountriesMap[CountryCode.FR],
    [phoneCountriesMap[CountryCode.BE].dialCode]:
      phoneCountriesMap[CountryCode.BE],
    [phoneCountriesMap[CountryCode.IT].dialCode]:
      phoneCountriesMap[CountryCode.IT],
    [phoneCountriesMap[CountryCode.AD].dialCode]:
      phoneCountriesMap[CountryCode.AD],
    [phoneCountriesMap[CountryCode.ES].dialCode]:
      phoneCountriesMap[CountryCode.ES],
    [phoneCountriesMap[CountryCode.LU].dialCode]:
      phoneCountriesMap[CountryCode.LU],
    [phoneCountriesMap[CountryCode.CH].dialCode]:
      phoneCountriesMap[CountryCode.CH],
    [phoneCountriesMap[CountryCode.MC].dialCode]:
      phoneCountriesMap[CountryCode.MC],
  };

export const findCountry = (value: string): PhoneCountry | undefined => {
  const regex = /^(?:\(\+|\+|00)(\d{2,3})/;
  const res = regex.exec(value);
  if (res && res.length >= 2) {
    let country: PhoneCountry | undefined;
    const countryCode3Char = res[1];
    const countryCode2Char =
      countryCode3Char.length > 2
        ? countryCode3Char.substring(0, 2)
        : countryCode3Char;

    country = countriesByDialCode[countryCode2Char];
    if (!country) {
      country = countriesByDialCode[countryCode3Char];
    }
    return country;
  }
  return undefined;
};

export const removeCountryCode = (
  value: string,
  country: PhoneCountry,
  addLeadingZero: boolean = true,
): string => {
  const regex = new RegExp(`^(?:\\(\\+|\\+|00)(${country.dialCode})\\)?`);
  const extractResult = regex.exec(value);
  if (extractResult && extractResult.length > 0) {
    const withoutLeadingZero = value.substring(extractResult[0].length);
    return addLeadingZero && country.leadingZero
      ? `0${withoutLeadingZero}`
      : withoutLeadingZero;
  }
  return value;
};

export const getPhone = (rawValue?: string): Phone => {
  if (!rawValue) {
    return {
      number: '',
      countryCode: CountryCode.FR,
    };
  }
  const country = findCountry(rawValue);
  if (!country) {
    return {
      number: rawValue,
      countryCode: CountryCode.FR,
    };
  }
  return {
    number: removeCountryCode(rawValue, country),
    countryCode: country.code,
  };
};

export const formatPhoneRequest = (phone: Phone) => {
  if (!phone.number) {
    return undefined;
  }
  let number = phone.number;
  const country = phoneCountriesMap[phone.countryCode];
  if (country.leadingZero && number.startsWith('0')) {
    number = number.substring(1);
  }
  return `+${country.dialCode}${number}`;
};

export const formatPhoneDisplay = (rawValue?: string): string | undefined => {
  if (!rawValue) {
    return undefined;
  }
  const country = findCountry(rawValue);
  if (!country) {
    return rawValue;
  }

  const value = removeCountryCode(rawValue, country, false).replaceAll(
    /\D/g,
    '',
  );
  if (country.code == CountryCode.FR) {
    const formatted =
      country.patternRegex
        .exec(value)
        ?.reduce((formatted, value, index) => {
          if (index === 0) {
            return '';
          }
          if (value === '') {
            return formatted;
          }
          return `${formatted} ${value}`;
        }, '')
        .trim() || '';

    return `0${formatted}`;
  }

  return `(+${country.dialCode})${value}`;
};
