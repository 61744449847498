import React, { useEffect, useMemo } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Link as LinkMUI } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AppBar from 'components/AppBar';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { projectStepsData } from 'models/oav/ProjectStep.models';
import { projectPhasesData } from 'models/oav/ProjectPhase.models';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { ProjectStatus } from 'models/oav/ProjectStatus.models';

const LayoutProject: React.FC = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (!id) return null;

  // Get project.
  const projectQuery = useQuery(projectQueries.getById(id));

  const title = useMemo(() => {
    const step = Object.entries(projectStepsData).find(([, v]) => {
      return pathname.endsWith(`/${v.path}`);
    });

    if (step == null) {
      return null;
    }

    const [, data] = step;

    return projectPhasesData[data.phase].label;
  }, [pathname]);

  const projectEdition =
    projectQuery.data?.status != ProjectStatus.SIGNATURE_DONE &&
    projectQuery.data?.status != ProjectStatus.MANAGEMENT_DONE &&
    projectQuery.data?.status != ProjectStatus.MANAGEMENT_FAILED &&
    projectQuery.data?.status != ProjectStatus.SIGNATURE_IN_PROGRESS &&
    projectQuery.data?.status != ProjectStatus.EXPIRED &&
    projectQuery.data?.status != ProjectStatus.CANCELED;

  // Check ProjectStep on path change.
  useEffect(() => {
    if (!projectQuery.data) return;

    // Redirect to projet edition if project status in edition
    if (projectEdition) {
      !pathname.startsWith(`/projets/${projectQuery.data?.id}/edition`) &&
        navigate(`/projets/${projectQuery.data?.id}/edition`, {
          replace: true,
        });
      return;
    }
  }, [pathname, projectQuery.data, projectEdition]);

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Breadcrumbs separator={<ChevronRightIcon size="small" />}>
            <Link to="/projets" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" color="inherit">
                Projets
              </LinkMUI>
            </Link>
            <Typography>{title}</Typography>
          </Breadcrumbs>
        </Stack>
      </AppBar>

      <Box
        sx={{
          width: '100%',
          maxWidth: 1250,
          mx: 'auto',
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default LayoutProject;
