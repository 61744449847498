import React, { JSX } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Stack from '@mui/material/Stack';

export type ProjectTypeButtonProps = {
  title: string;
  info?: string;
  icon: JSX.Element;
  onClick?: () => void;
  loading?: boolean;
};

export const ProjectTypeButton: React.FC<ProjectTypeButtonProps> = ({
  title,
  icon,
  info,
  onClick,
  loading,
}) => {
  const theme = useTheme();
  return (
    <Card
      onClick={onClick}
      sx={{
        width: { md: 400, xs: 300 },
        height: { md: 280, xs: 300 },
        p: 2,
        cursor: 'pointer',
        display: 'grid',
        gridTemplateRows: { md: '1fr 100px 2fr', xs: '1fr 80px 2fr' },
        alignItems: 'center',
        justifyItems: 'center',
        gap: 3,
        ':hover': {
          boxShadow: '0 0 20px ' + theme.palette.text.primary,
        },
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        {loading && <CircularProgress size={18} />}
        <Typography variant="h3" sx={{ mb: 'auto' }}>
          {title}
        </Typography>
      </Stack>
      {React.cloneElement(icon, {
        width: '100%',
        height: '100%',
      })}
      {info != null && (
        <Card
          color="darken"
          sx={{
            py: 1,
            px: 2,
          }}
        >
          <Typography variant="body1" sx={{ lineHeight: '1.2rem' }}>
            {info}
          </Typography>
        </Card>
      )}
    </Card>
  );
};
