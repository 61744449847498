import { ReactNode } from 'react';

import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

export type CardSelectProps = {
  children: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  onSelect?: (checked: boolean) => void;
  cardProps?: CardProps;
};

const CardSelect = (props: CardSelectProps) => {
  const theme = useTheme();

  const enabled = !props.disabled && props.onSelect;
  const cardSx = props.cardProps?.sx;

  return (
    <Card
      {...props.cardProps}
      onClick={() => !props.disabled && props.onSelect?.(!props.selected)}
      sx={{
        p: 0,
        outline: props.selected ? 2 : 0,
        outlineColor: props.selected ? theme.palette.primary.main : 'none',
        ...cardSx,
      }}
    >
      <Box
        sx={{
          p: 0.5,
          transition:
            'color 150ms ease-in-out, background-color 150ms ease-in-out',
          cursor: enabled ? 'pointer' : 'default',
          ':hover': enabled
            ? {
                background: alpha('#000000', 0.15),
                color: theme.palette.primary.main,
              }
            : {},
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{ px: 1, flexGrow: 1 }}>{props.children}</Box>
        <Stack direction="row">
          <Checkbox
            size="small"
            checked={props.selected === true}
            disabled={props.disabled}
          />
        </Stack>
      </Box>
    </Card>
  );
};

export default CardSelect;
