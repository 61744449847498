import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { produce } from 'immer';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import { RestError } from 'errors/RestError';
import CardError from 'components/Card/CardError';
import { useAppContext } from 'components/Context/AppContext';
import { useProjectContext } from 'components/Context/ProjectContext';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { Civility } from 'models/oav/Person.models';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import {
  ProjectStepClientsInfo,
  ProjectStepClientsInfoErrors,
} from 'models/oav/Project.models';
import { FormikObserver } from 'utils/formik/FormikObserver';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { messageFieldRequired } from 'utils/validation/message';
import { yupFieldPhone } from 'utils/validation/yupPerson';
import { BeneficiaryType } from 'models/oav/Beneficiary.models';
import FormBeneficiaryExpressCard from 'content/form/FormBeneficiaryExpressCard';
import { FormSubscriberClientInfo } from 'content/form/ClientsInfoForm/FormSubscriberClientInfo';
import { FormClientInfo } from 'content/form/ClientsInfoForm/FormClientInfo';
import Stack from '@mui/material/Stack';
import { emptyStringToUndefined } from 'utils/fields/fields';
import {
  getBeneficiariesLinkedToSubscription,
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import { isBadRequestWithDetailsError } from 'utils/api/api';
import { projectStepMutations } from 'api/oav/ProjectStepQuery.api';
import { arrayToMap } from 'utils/utils';
import { formatPhoneRequest, getPhone } from 'utils/phone';
import { Phone } from 'models/Phone.models';

export interface FormClientInfoValues {
  id: string;
  lastname: string;
  firstname: string;
  civility?: Civility;
}

export interface FormSubscriberClientInfoValues {
  email: string;
  phone?: Phone;
  landline?: Phone;
}

export interface FormClientsInfoValues {
  subscriber: FormSubscriberClientInfoValues;
  partner?: FormClientInfoValues;
  children?: FormClientInfoValues[];
}

const CURRENT_STEP = ProjectStep.CLIENTS_INFO;

const clientValidationSchema = Yup.object()
  .shape({
    civility: Yup.string().nullable().required(messageFieldRequired),
    firstname: Yup.string().nullable().required(messageFieldRequired),
    lastname: Yup.string().nullable().required(messageFieldRequired),
  })
  .default(null)
  .nullable();

const subscriberValidationSchema = Yup.object().shape({
  email: Yup.string().email('Email non valide'),
  phone: yupFieldPhone.test(
    'at-least-one-property',
    'Au moins un numéro de téléphone doit être renseigné',
    (item, testContext) => {
      return !!(
        (testContext?.parent.landline &&
          formatPhoneRequest(testContext?.parent.landline)) ||
        (item && formatPhoneRequest(item as Phone))
      );
    },
  ),
  landline: yupFieldPhone,
});

const validationSchema = Yup.object().shape({
  subscriber: subscriberValidationSchema,
  partner: clientValidationSchema,
  children: Yup.array().of(clientValidationSchema).default(null).nullable(),
});

const ProjectContactInformationsPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  if (!id) return <></>;

  const { setDataCurrent } = useProjectContext();
  const { addSnackbar } = useAppContext();
  const theme = useTheme();

  const projectQuery = useQuery(projectQueries.getById(id));

  const formikRef = useRef<FormikProps<FormClientsInfoValues>>(null);

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1);
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [location]);

  const { children, partner, initialValues } = useMemo(() => {
    if (!projectQuery.data) return {};

    const subscriber = projectQuery.data.subscriber;
    const { partner, children } = getBeneficiariesLinkedToSubscription(
      projectQuery.data,
    );

    const initialValues = {
      subscriber: {
        email: subscriber.person.email || '',
        phone: getPhone(subscriber.person.phone),
        landline: getPhone(subscriber.person.landline),
      },
      partner: partner
        ? {
            id: partner.id || '',
            lastname: partner.person.lastname || '',
            firstname: partner.person.firstname || '',
            civility: partner.person.civility,
          }
        : undefined,
      children: children?.map(_ => ({
        id: _.id || '',
        lastname: _.person.lastname || '',
        firstname: _.person.firstname || '',
        civility: _.person.civility,
      })),
    };

    return {
      partner,
      children,
      initialValues,
    };
  }, [projectQuery.data]);

  const { mutate, isPending } =
    projectStepMutations.updateProjectStepClientsInfo(id, {
      onSuccess: () => {
        navigateToNextStep(CURRENT_STEP, id, navigate);
      },
      onError: (error: Error) => {
        if (
          isBadRequestWithDetailsError<ProjectStepClientsInfoErrors>(error) &&
          error.response?.details
        ) {
          onBadRequest(error.response?.details);
        } else {
          addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
        }
      },
    });

  const onBadRequest = (errorDetails: ProjectStepClientsInfoErrors) => {
    if (errorDetails.subscriber) {
      Object.entries(errorDetails.subscriber).forEach(([key, value]) => {
        formikRef.current?.setFieldError(`subscriber.${key}`, value);
        formikRef.current?.setFieldTouched(`subscriber.${key}`, true, false);
      });
    }
    if (errorDetails.partner) {
      Object.entries(errorDetails.subscriber).forEach(([key, value]) => {
        formikRef.current?.setFieldError(`partner.${key}`, value);
        formikRef.current?.setFieldTouched(`partner.${key}`, true, false);
      });
    }
    Object.entries(errorDetails)
      .filter(([key]) => key.startsWith('children['))
      .forEach(([key, value]) => {
        const prefixPath = key.replace('[', '.').replace(']', '');
        Object.entries(value as object).forEach(([k, v]) => {
          formikRef.current?.setFieldError(`${prefixPath}.${k}`, v);
          formikRef.current?.setFieldTouched(
            `${prefixPath}.${key}`,
            true,
            false,
          );
        });
      });
  };

  const handleSubmit = (values: FormClientsInfoValues) => {
    const request: ProjectStepClientsInfo = {
      subscriber: {
        ...values.subscriber,
        phone:
          values.subscriber.phone &&
          formatPhoneRequest(values.subscriber.phone),
        landline:
          values.subscriber.landline &&
          formatPhoneRequest(values.subscriber.landline),
      },
    };

    if (values.partner) {
      request.partner = {
        ...emptyStringToUndefined(values.partner),
        civility: values.partner.civility!,
      };
    }

    if (values.children?.length) {
      request.children = values.children.map(_ => ({
        ...emptyStringToUndefined(_),
        civility: _.civility!,
      }));
    }

    mutate(request);
  };

  const onChangeValues = (values: FormClientsInfoValues) => {
    setDataCurrent(
      produce(({ project }) => {
        if (!project) {
          return;
        }
        const subscriberValues = emptyStringToUndefined(values.subscriber);
        const subscriber = project.subscriber.person;
        subscriber.email = subscriberValues.email;
        subscriber.phone = subscriberValues.phone?.number;
        subscriber.landline = subscriberValues.landline?.number;

        if (project.partner?.person && values.partner) {
          const partnerValues = emptyStringToUndefined(values.partner);
          const partner = project.partner.person;
          partner.civility = partnerValues.civility;
          partner.firstname = partnerValues.firstname;
          partner.lastname = partnerValues.lastname;
        }

        if (project.children?.length && values.children?.length) {
          const childrenMap = arrayToMap(values.children, 'id');
          project.children.forEach(child => {
            if (child.id && childrenMap[child.id]) {
              const childValues = emptyStringToUndefined(childrenMap[child.id]);
              child.person.civility = childValues.civility;
              child.person.firstname = childValues.firstname;
              child.person.lastname = childValues.lastname;
            }
          });
        }
      }),
    );
  };

  if (projectQuery.error) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={
            projectQuery.error instanceof RestError
              ? projectQuery.error.status
              : undefined
          }
        />
      </Grid>
    );
  }

  if (!projectQuery.data || !initialValues) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <Form>
            <Grid container justifyContent="center" alignItems="center" gap={2}>
              <Grid item xs={12}>
                <Typography variant="h3">Données clients</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormikObserver onChange={onChangeValues} />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1,
                        border: 2,
                        borderColor: theme.palette.divider,
                      }}
                      id="souscripteur"
                    >
                      <FormBeneficiaryExpressCard
                        type={BeneficiaryType.SUBSCRIBER}
                      >
                        <FormSubscriberClientInfo
                          subscriber={projectQuery.data.subscriber}
                        />
                      </FormBeneficiaryExpressCard>
                    </Card>
                  </Grid>
                  {partner && (
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          p: 1,
                          border: 2,
                          borderColor: theme.palette.divider,
                        }}
                        id="conjoint"
                      >
                        <FormBeneficiaryExpressCard
                          type={BeneficiaryType.PARTNER}
                        >
                          <FormClientInfo
                            beneficiary={partner}
                            name="partner"
                          />
                        </FormBeneficiaryExpressCard>
                      </Card>
                    </Grid>
                  )}
                  {children && children.length > 0 && (
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          p: 1,
                          border: 2,
                          borderColor: theme.palette.divider,
                        }}
                      >
                        <FormBeneficiaryExpressCard
                          type={BeneficiaryType.CHILD}
                        >
                          <Grid container spacing={2}>
                            {children.map((_, idx) => (
                              <Grid item xs={12} key={_.id}>
                                <Stack id={`enfant${idx + 1}`} key={idx}>
                                  <Typography sx={{ pb: 1 }}>
                                    Enfant n° {idx + 1}
                                  </Typography>
                                  <FormClientInfo
                                    beneficiary={_}
                                    name={`children.${idx}`}
                                  />
                                </Stack>
                              </Grid>
                            ))}
                          </Grid>
                        </FormBeneficiaryExpressCard>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  color="default"
                  disabled={isPending}
                  onClick={() =>
                    navigateToPreviousStep(CURRENT_STEP, id, navigate)
                  }
                  sx={{ px: 4 }}
                >
                  Retour
                </Button>
              </Grid>

              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  disabled={isPending}
                  sx={{ px: 4 }}
                >
                  {isPending ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    'Valider'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectContactInformationsPage;
