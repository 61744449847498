import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';

import PriceNumber from 'content/formula/PriceNumber';
import CardSolutionItem from 'content/solution/CardSolutionItem';
import ChipPeriod from 'content/solution/ChipPeriod';

import {
  Solution,
  SolutionItem,
  TarificationPeriod,
} from 'models/oav/Solution.models';
import { Offer } from 'models/referentiels/Offer.model';
import CardSolutionItemOption from 'content/solution/CardSolutionItemOption';
import Divider from '@mui/material/Divider';

export type CardSolutionProps = {
  solution: Solution;
  offer?: Offer;
  selected?: boolean;
  disabled?: boolean;
  period?: TarificationPeriod;
  onPeriodChange?: (period: TarificationPeriod) => void;
  onSelect?: () => void;
  onDelete?: () => void;
  onClickItem?: (item: SolutionItem) => void;
  onClick?: () => void;
};

const CardSolution = (props: CardSolutionProps) => {
  const theme = useTheme();

  const enabled = !props.disabled && props.onClick;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 0,
        outline: props.selected ? 2 : 0,
        outlineColor: props.selected ? theme.palette.primary.main : 'none',
      }}
    >
      <Box
        onClick={() => !props.disabled && props.onClick?.()}
        sx={{
          p: 0.5,
          cursor: enabled ? 'pointer' : 'default',
          ':hover': enabled
            ? {
                color: theme.palette.primary.main,
              }
            : {},
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            fontWeight="bold"
            sx={{
              px: 1,
              flexGrow: 1,
            }}
          >
            Solution {props.solution.order && ` ${props.solution.order}`}
          </Typography>
          <Stack direction="row">
            {props.onDelete && (
              <Tooltip
                placement="top"
                title={'Supprimer'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: theme.palette.error.main,
                    },
                  },
                }}
              >
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    props.onDelete?.();
                  }}
                  sx={{
                    ':hover': {
                      color: theme.palette.error.main,
                      background: alpha(theme.palette.error.main, 0.1),
                    },
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {props.onSelect && (
              <Checkbox
                size="small"
                checked={props.selected === true}
                disabled={props.disabled}
              />
            )}
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          px: 1.5,
          pt: 1,
          textAlign: 'right',
        }}
      >
        <ChipPeriod period={props.period} onChange={props.onPeriodChange} />
      </Box>

      <Stack
        spacing={0}
        sx={{
          containerType: 'inline-size',
          py: 1.5,
          flexGrow: 1,
        }}
      >
        {/* BASE */}
        <CardSolutionItem
          offer={props.solution.base.offer}
          formula={props.solution.base.formula}
          pricing={props.solution.base.pricing}
          period={props.period}
          onClick={
            props.onClickItem &&
            (e => {
              e.stopPropagation();
              props.onClickItem?.(props.solution.base);
            })
          }
        />
        <Divider
          sx={{
            mx: 1.5,
            my: 1,
            borderColor: props.selected ? theme.palette.primary.main : '',
          }}
        />

        <Typography
          component="div"
          variant="caption"
          sx={{
            px: 1.5,
            '@container (max-width: 200px)': {
              textAlign: 'right',
              width: '100%',
            },
          }}
        >
          Options
        </Typography>
        {/* REINFORCEMENTS */}
        {props.solution.reinforcements != null &&
          props.solution.reinforcements.map(_ => (
            <CardSolutionItemOption
              key={_.offer.code}
              formula={_.formula}
              pricing={_.pricing}
              period={props.period}
              onClick={
                props.onClickItem &&
                (e => {
                  e.stopPropagation();
                  props.onClickItem?.(_);
                })
              }
            />
          ))}

        {/* OPTIONS */}
        {props.solution.options != null &&
          props.solution.options.map(_ => (
            <CardSolutionItemOption
              key={_.offer.code}
              formula={_.formula}
              pricing={_.pricing}
              period={props.period}
              onClick={
                props.onClickItem &&
                (e => {
                  e.stopPropagation();
                  props.onClickItem?.(_);
                })
              }
            />
          ))}

        {props.solution.options == null &&
          props.solution.reinforcements == null && (
            <Typography
              variant="caption"
              sx={{
                px: 1.5,
                '@container (max-width: 200px)': {
                  textAlign: 'right',
                  width: '100%',
                },
              }}
            >
              Sans option
            </Typography>
          )}
      </Stack>

      <Box
        sx={{
          containerType: 'inline-size',
          p: 1.5,
        }}
      >
        <Divider
          sx={{
            mb: 1,
            borderColor: props.selected ? theme.palette.primary.main : '',
          }}
        />
        <Stack direction="row" flexWrap="wrap">
          <Typography
            fontWeight="bold"
            sx={{
              '@container (max-width: 200px)': {
                textAlign: 'right',
                width: '100%',
              },
            }}
          >
            Total
          </Typography>
          <Stack
            direction={
              props.period === TarificationPeriod.ANNUALLY
                ? 'column-reverse'
                : 'column'
            }
            sx={{
              containerType: 'inline-size',
              width: '100%',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
              spacing={1}
            >
              <Typography variant="caption">par mois</Typography>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: 'right',
                  '@container (max-width: 200px)': {
                    width: '100%',
                  },
                }}
              >
                <PriceNumber price={props.solution.pricing?.perMonth} />
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
              spacing={1}
            >
              <Typography variant="caption">par an</Typography>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: 'right',
                  '@container (max-width: 200px)': {
                    width: '100%',
                  },
                }}
              >
                <PriceNumber price={props.solution.pricing?.perYear} />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default CardSolution;
