import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { messageFieldRequired } from 'utils/validation/message';
import {
  ageAdultMax,
  ageAdultMin,
  ageChildMax,
  ageChildMin,
  getDateFromAge,
  getYupFieldBirthDateAdult,
  getYupFieldBirthDateChild,
  yupFieldPostalCode,
} from 'utils/validation/yupPerson';
import DatePicker from 'components/DatePicker/DatePicker';
import TextField from 'components/TextField/TextField';
import { AutocompleteRegime } from 'content/regime/AutocompleteRegime';

import {
  BeneficiaryRequestPost,
  BeneficiaryRequestPostErrors,
  BeneficiaryType,
} from 'models/oav/Beneficiary.models';
import { personStatusQueries } from 'api/referentiels/PersonStatusQuery.api';

import { FormikObserver } from '../../utils/formik/FormikObserver';

export interface FormBeneficiaryExpressValues {
  personId?: string;
  beneficiaryId?: string;
  type: BeneficiaryType;
  postalCode?: string;
  birthDate?: Date | null;
  regimeCode?: string;
  statusCode?: string;
}

export interface FormBeneficiaryExpressErrors {
  postalCode?: string;
  birthDate?: string;
  regimeCode?: string;
  statusCode?: string;
}

export interface FormBeneficiaryExpressRef {
  resetForm: () => void;
  validateForm: () => Promise<any>;
  values: FormBeneficiaryExpressValues;
}

export interface FormBeneficiaryExpressProps {
  type: BeneficiaryType;
  initialValues?: FormBeneficiaryExpressValues;
  errors?: FormikErrors<FormBeneficiaryExpressValues>;
  onChangeValues?: (_: FormBeneficiaryExpressValues) => void;
}

export const formBeneficiaryExpressValuesToDto = (
  values: FormBeneficiaryExpressValues,
) => {
  const payload: BeneficiaryRequestPost = {
    beneficiaryId: values?.beneficiaryId,
    personId: values?.personId,
    type: values.type,
    birthdate: values!.birthDate as Date,
    regimeCode: values.regimeCode,
    postalCode: values.postalCode,
    statusCode: values.statusCode,
  };

  return payload;
};

export const badRequestToFormBeneficiaryExpressErrors = (
  errors: BeneficiaryRequestPostErrors,
) => {
  const payload: FormBeneficiaryExpressErrors = {
    birthDate: errors.birthdate,
    regimeCode: errors.regimeCode,
    postalCode: errors.postalCode,
    statusCode: errors.statusCode,
  };

  return payload;
};

const FormBeneficiaryExpress = forwardRef(
  (props: FormBeneficiaryExpressProps, ref: any) => {
    // ForwardRef methods.
    useImperativeHandle(ref, () => ({
      resetForm: () => {
        formikRef.current!.resetForm();
      },
      validateForm: () => {
        formikRef.current!.setTouched({
          postalCode: true,
          birthDate: true,
          regimeCode: true,
          statusCode: true,
        });
        return formikRef.current!.validateForm();
      },
      get values() {
        return formikRef.current!.values;
      },
    }));

    // Form.
    const formikRef = useRef<FormikProps<FormBeneficiaryExpressValues>>(null);

    const personStatusQuery = useQuery(personStatusQueries.getAll());

    const ageMin =
      props.type === BeneficiaryType.CHILD ? ageChildMin : ageAdultMin;
    const ageMax =
      props.type === BeneficiaryType.CHILD ? ageChildMax : ageAdultMax;

    const validationSchema = Yup.object().shape({
      type: Yup.string(),
      postalCode: yupFieldPostalCode.when('type', {
        is: (value: string) => value === BeneficiaryType.SUBSCRIBER.toString(),
        then: schema => schema.required(messageFieldRequired),
      }),
      birthDate: (props.type === BeneficiaryType.CHILD
        ? getYupFieldBirthDateChild()
        : getYupFieldBirthDateAdult()
      ).required(messageFieldRequired),
      regimeCode: Yup.string().required(messageFieldRequired),
      statusCode: Yup.string(),
    });

    return (
      <Formik
        innerRef={formikRef}
        initialValues={
          props.initialValues || {
            type: props.type,
            postalCode: undefined,
            birthDate: undefined,
            regimeCode: undefined,
            statusCode: undefined,
          }
        }
        validationSchema={validationSchema}
        onSubmit={(
          values,
          { setSubmitting }: FormikHelpers<FormBeneficiaryExpressValues>,
        ) => {
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          setErrors,
        }) => {
          useEffect(() => {
            if (Object.keys(values).length > 0 && props.onChangeValues) {
              props.onChangeValues(values);
            }
          }, [values]);

          useEffect(() => {
            if (!props.errors) return;
            setErrors({ ...errors, ...props.errors });
            Object.entries(props.errors).forEach(_ => {
              setFieldTouched(_[0], true, false);
            });
          }, [props.errors]);

          return (
            <Form>
              {props.onChangeValues && (
                <FormikObserver onChange={props.onChangeValues} />
              )}

              <Grid
                container
                spacing={{
                  sm: 2,
                  xs: 1,
                }}
              >
                {props.type === BeneficiaryType.SUBSCRIBER && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required={props.type === BeneficiaryType.SUBSCRIBER}
                      id="postalCode"
                      name="postalCode"
                      label="Code postal"
                      value={values.postalCode}
                      error={touched.postalCode && Boolean(errors.postalCode)}
                      helperText={
                        touched.postalCode && <>{errors.postalCode}</>
                      }
                      onChange={e =>
                        setFieldValue('postalCode', e.target.value)
                      }
                      onBlur={e => setFieldTouched('postalCode')}
                      inputProps={{ minLength: 5, maxLength: 5 }}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DatePicker
                    sx={{
                      width: '100%',
                    }}
                    label="Date de naissance"
                    format="DD/MM/YYYY"
                    openTo="year"
                    value={values.birthDate}
                    minDate={dayjs(getDateFromAge(ageMax))}
                    maxDate={dayjs(getDateFromAge(ageMin))}
                    onChange={v => setFieldValue('birthDate', v)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        onBlur: e => setFieldTouched('birthDate'),
                        error: touched.birthDate && Boolean(errors.birthDate),
                        helperText: touched.birthDate &&
                          Boolean(errors.birthDate) && <>{errors.birthDate}</>,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteRegime
                    fullWidth
                    required
                    id="regimeCode"
                    label="Régime social"
                    value={values.regimeCode}
                    error={!!touched.regimeCode && Boolean(errors.regimeCode)}
                    helperText={touched.regimeCode && errors.regimeCode}
                    onChange={(e, v) => setFieldValue('regimeCode', v)}
                    onBlur={() => setFieldTouched('regimeCode')}
                  />
                </Grid>

                {props.type === BeneficiaryType.SUBSCRIBER && (
                  <Grid item xs={12}>
                    {personStatusQuery.isLoading ||
                      (personStatusQuery.isError && (
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          height="50px"
                          sx={{ borderRadius: 2 }}
                        />
                      ))}

                    {personStatusQuery.data && (
                      <Grid container spacing={2}>
                        {personStatusQuery.data &&
                          personStatusQuery.data.map(_ => (
                            <Grid item xs={12}>
                              {/* DISABLED : They want checkbox because there's only one status for now. */}
                              {/* <CardPersonStatus
                              selected={values.statusCode === _.code}
                              personStatus={_}
                              onClick={(v) => {
                                v ? setFieldValue('statusCode', _.code) : setFieldValue('statusCode', undefined);
                              }} /> */}

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.statusCode === _.code}
                                    onChange={e =>
                                      e.target.checked
                                        ? setFieldValue('statusCode', _.code)
                                        : setFieldValue('statusCode', undefined)
                                    }
                                  />
                                }
                                label={_.label}
                                sx={{ pl: 1 }}
                              />
                            </Grid>
                          ))}
                        <FormHelperText
                          error={
                            touched.statusCode && Boolean(errors.statusCode)
                          }
                          sx={{ pl: 2 }}
                        >
                          {errors.statusCode}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  },
);

export default memo(FormBeneficiaryExpress);
