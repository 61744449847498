import { UserInfo } from 'models/UserInfo.model';

const keycloakURL = process.env.REACT_APP_KEYCLOAK_AUTHORITY;

export const loadUserInfo = (token?: string): Promise<UserInfo> => {
  return fetch(`${keycloakURL}/protocol/openid-connect/userinfo`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(_ => _.json());
};
