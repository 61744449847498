import { getRequest } from 'utils/queries/queries.utils';
import { City } from 'models/external/City.model';

export const fetchCityByPostalCode = async (
  postalCode: string,
): Promise<City[]> => {
  const url = `https://datanova.laposte.fr/data-fair/api/v1/datasets/laposte-hexasmal/lines?select=nom_de_la_commune%2Ccode_postal&q_fields=code_postal&q=${postalCode}`;

  const result: {
    total: number;
    results: [{ code_postal: number; nom_de_la_commune: string }];
  } = await getRequest(url);

  return result.results
    .map((data): string => data.nom_de_la_commune)
    .filter((string, index, array) => {
      return array.indexOf(string) == index;
    })
    .map(
      (name): City => ({
        name: name,
        postalCode: postalCode,
      }),
    );
};
