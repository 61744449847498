import React, { useEffect, useMemo, useRef, useState } from 'react';

import * as Yup from 'yup';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik, FormikProps } from 'formik';

import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { RestError } from 'errors/RestError';

import CardSubscription from 'content/subscription/CardSubscription';
import GridSubscriptionSolutions from 'content/subscription/GridSubscriptionSolutions';
import { useAppContext } from 'components/Context/AppContext';
import { useProjectContext } from 'components/Context/ProjectContext';

import { ProjectStep } from 'models/oav/ProjectStep.models';
import { ProjectStepSolutionSelectionErrors } from 'models/oav/Project.models';
import { Subscription } from 'models/oav/Subscription.models';

import { projectQueries } from 'api/oav/ProjectQuery.api';
import { projectStepMutations } from 'api/oav/ProjectStepQuery.api';

const CURRENT_STEP = ProjectStep.SOLUTION_SELECTION;

const ProjectFormulaChoicePage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { addSnackbar } = useAppContext();

  if (!id) return <></>;

  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const getSubscriptionFromOfferCode = (code: string) => {
    const subscriptions = projectQuery.data?.subscriptions;
    if (!subscriptions) return;

    const sub = subscriptions.find(_ => _.offerCode === code);
    if (sub) return sub;
    else if (subscriptions.length > 0) return subscriptions[0];
  };

  const [subscriptionCurrent, setSubscriptionCurrent] = useState<
    Subscription | undefined
  >(getSubscriptionFromOfferCode(searchParams.get('offre') as string));

  useEffect(() => {
    setSubscriptionCurrent(
      getSubscriptionFromOfferCode(searchParams.get('offre') as string),
    );
  }, [projectQuery?.data]);

  useEffect(() => {
    if (!subscriptionCurrent?.offerCode) return;
    setSearchParams(prev => ({
      ...prev,
      offre: subscriptionCurrent.offerCode,
    }));
  }, [subscriptionCurrent?.offerCode]);

  const offer = useMemo(() => {
    if (subscriptionCurrent && offersMapQuery.data) {
      return offersMapQuery.data[subscriptionCurrent.offerCode];
    }
  }, [subscriptionCurrent, offersMapQuery.data]);

  const contextProject = useProjectContext();

  type FormValues = { [key: string]: string | undefined };

  const initialValues: FormValues = useMemo(() => {
    const values: FormValues = {};
    projectQuery.data?.subscriptions?.forEach(_ => {
      values[_.id] = _.solutionId;
    });

    return values;
  }, [projectQuery.data]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      Object.keys(initialValues).reduce<Record<string, Yup.StringSchema>>(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: Yup.string().required(
            'Vous devez sélectionner une solution',
          ),
        }),
        {},
      ),
    );
  }, [initialValues]);

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const mutation = projectStepMutations.updateProjectStepSolutionSelection(id, {
    onSuccess: () => {
      navigateToNextStep(CURRENT_STEP, id, navigate);
    },
    onError: error => {
      if (error instanceof RestError && error.status === 400) {
        const errors = error.response?.details;
        if (errors) onBadRequest(errors);
      } else {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      }
    },
  });

  const onBadRequest = (errors: ProjectStepSolutionSelectionErrors) => {};

  if (
    !projectQuery.data ||
    !offersMapQuery.data ||
    !subscriptionCurrent ||
    !offer
  ) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" gap={2}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          gap={1}
        >
          <Typography variant="h3" flexGrow={1}>
            Choix de la couverture
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            mutation.mutate({
              subscriptions: Object.entries(values).map(([key, value]) => ({
                id: key,
                solutionId: value!,
              })),
            });
          }}
        >
          {({ errors, values, setFieldValue }) => {
            useEffect(
              () =>
                contextProject.setDataCurrent(prev => {
                  if (!prev.project) return prev;

                  if (prev.project.subscriptions) {
                    const subs = prev.project.subscriptions.map(_ => {
                      if (values[_.id]) _.solutionId = values[_.id];
                      return _;
                    });

                    prev = {
                      ...prev,
                      project: {
                        ...prev.project,
                        subscriptions: [...subs],
                      },
                    };
                  }

                  return prev;
                }),
              [values],
            );

            return (
              <Form>
                <CardSubscription
                  project={projectQuery.data}
                  subscription={subscriptionCurrent}
                  offer={offer}
                  showForm
                  onUpdate={_ => setSubscriptionCurrent(_)}
                >
                  <Stack gap={2}>
                    <GridSubscriptionSolutions
                      project={projectQuery.data}
                      subscription={subscriptionCurrent}
                      offer={offer}
                      selectedSolutionId={values[subscriptionCurrent.id]}
                      onSelectSolution={_ => {}}
                      onClickSolution={_ => {
                        setFieldValue(
                          subscriptionCurrent.id,
                          values[subscriptionCurrent.id] === _.id
                            ? undefined
                            : _.id,
                        );
                      }}
                    />

                    {errors[subscriptionCurrent.id] && (
                      <FormHelperText error>
                        {errors[subscriptionCurrent.id]}
                      </FormHelperText>
                    )}
                  </Stack>
                </CardSubscription>
              </Form>
            );
          }}
        </Formik>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <Button
          fullWidth
          color="default"
          disabled={mutation.isPending}
          onClick={() => navigateToPreviousStep(CURRENT_STEP, id, navigate)}
          sx={{ px: 4 }}
        >
          Retour
        </Button>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <Button
          fullWidth
          type="submit"
          disabled={mutation.isPending}
          sx={{ px: 4 }}
          onClick={() => formikRef.current!.submitForm()}
        >
          Valider
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectFormulaChoicePage;
