import { alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  SolutionFormula,
  SolutionPricing,
  TarificationPeriod,
} from 'models/oav/Solution.models';

import PriceNumber from 'content/formula/PriceNumber';
import React from 'react';

export type CardSolutionItemOptionProps = {
  formula?: SolutionFormula;
  pricing?: SolutionPricing;
  disabled?: boolean;
  period?: TarificationPeriod;
  onClick?: (e: React.MouseEvent) => void;
};

const CardSolutionItemOption = (props: CardSolutionItemOptionProps) => {
  const enabled = !props.disabled && props.onClick;

  return (
    <Box
      onClick={e => props.onClick?.(e)}
      sx={{
        containerType: 'inline-size',
        px: 1.5,
        py: 0.2,
        cursor: enabled ? 'pointer' : 'default',
        ':hover': {
          background: enabled ? alpha('#000000', 0.05) : 'none',
        },
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="flex-end"
        flexWrap="wrap"
        sx={{
          containerType: 'inline-size',
        }}
      >
        <Typography
          sx={{
            '@container (max-width: 200px)': {
              textAlign: 'right',
              width: '100%',
            },
            color: props.formula?.color || 'none',
          }}
        >
          {props.formula?.label || '-'}
        </Typography>

        <Typography
          sx={{
            flexGrow: 1,
            textAlign: 'right',
            '@container (max-width: 200px)': {
              width: '100%',
            },
          }}
        >
          <PriceNumber
            price={
              props.period === TarificationPeriod.ANNUALLY
                ? props.pricing?.perYear
                : props.pricing?.perMonth
            }
          />
        </Typography>
      </Stack>
    </Box>
  );
};

export default CardSolutionItemOption;
