import { UserUpdateRequest } from 'models/authentication/user';
import { queryOptions, useMutation } from '@tanstack/react-query';
import {
  signinToHenner,
  updateUserInfo,
} from 'api/authentication/Authentication.api';
import { useAccessToken } from 'utils/api/api';

const updateUserInfoMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (err: Error) => void;
}) => {
  const token = useAccessToken();

  return useMutation({
    mutationFn: (payload: UserUpdateRequest) => updateUserInfo(payload, token),
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export const getSigninToHennerQueryOptions = () => {
  const accessToken = useAccessToken();
  return queryOptions({
    queryKey: ['auth-to-henner'],
    gcTime: 0,
    staleTime: 0,
    queryFn: () => signinToHenner(accessToken),
  });
};

export const authenticationMutations = {
  updateUserInfo: updateUserInfoMutation,
};
