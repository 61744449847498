import { RestError } from 'errors/RestError';
import { captureError } from 'sentry/SentryConfig';

export type ResponseType = 'json' | 'blob';

const httpRequest = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  headers: Record<string, string>,
  accessToken?: string,
  body?: unknown,
  responseType: ResponseType = 'json',
) => {
  const options: RequestInit = {
    method,
    headers: accessToken
      ? { ...headers, Authorization: `Bearer ${accessToken}` }
      : headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);
  return await handleResponse(
    response,
    method,
    url,
    body ? JSON.stringify(body) : undefined,
    responseType,
  );
};

export const getRequest = (
  url: string,
  accessToken?: string,
  headers = { Accept: 'application/json' },
  responseType: ResponseType = 'json',
) => httpRequest('GET', url, headers, accessToken, undefined, responseType);

export const postRequest = (
  url: string,
  body: unknown,
  accessToken: string,
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseType: ResponseType = 'json',
) => httpRequest('POST', url, headers, accessToken, body, responseType);

export const putRequest = (
  url: string,
  body: unknown,
  accessToken: string,
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseType: ResponseType = 'json',
) => httpRequest('PUT', url, headers, accessToken, body, responseType);

export const deleteRequest = (
  url: string,
  accessToken: string,
  headers = { Accept: 'application/json' },
  responseType: ResponseType = 'json',
) => httpRequest('DELETE', url, headers, accessToken, responseType);

const handleResponse = async (
  response: Response,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  url: string,
  strBody: string | undefined,
  responseType: ResponseType = 'json',
): Promise<any> => {
  if (response.status / 100 >= 4) {
    const error = await response.json();
    captureError(`${method} - ${url}`, response.status, { response: error });
    throw new RestError(response.status, url, method, strBody, error);
  }

  if (response.status === 204) {
    return;
  }

  if (responseType === 'json') {
    return await response.json();
  }
  return await response.blob();
};
