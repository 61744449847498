import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { projectMutations } from 'api/oav/ProjectQuery.api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAppContext } from 'components/Context/AppContext';

export interface DialogCloseProjectProps extends DialogProps {
  projectId?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const DialogCancelProject: React.FC<DialogCloseProjectProps> = props => {
  const { addSnackbar } = useAppContext();
  const signatureCancelMutation = projectMutations.useCancelProjectMutation({
    onSuccess: props.onSuccess,
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  return (
    <Dialog {...props} fullWidth>
      <DialogTitle>Clôture du projet</DialogTitle>

      <DialogContent>Souhaitez-vous clôturer ce projet ?</DialogContent>

      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              disabled={signatureCancelMutation.isPending}
              onClick={() => props.onCancel != null && props.onCancel()}
              sx={{ px: 4 }}
            >
              Retour
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              onClick={() => {
                if (props.projectId == null) {
                  return;
                }
                signatureCancelMutation.mutate({ projectId: props.projectId });
              }}
              disabled={signatureCancelMutation.isPending}
              sx={{ px: 4 }}
              color="error"
            >
              {signatureCancelMutation.isPending ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Clôturer'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
