import * as Yup from 'yup';
import {
  getYupBirthDate,
  yupBirthOrder,
  yupCpamId,
  yupEmail,
  yupFieldFirstName,
  yupFieldLastName,
  yupFieldPhone,
  yupFieldPostalCode,
  yupNni,
} from 'utils/validation/yupPerson';
import { messageFieldRequired } from 'utils/validation/message';
import { PersonFormValues } from 'models/Form.model';
import { Person, PersonRequest } from 'models/oav/Person.models';
import {
  yupAddressLine1,
  yupAddressLine2,
  yupAddressLine3,
  yupCity,
} from 'utils/validation/yupAddress';
import { formatPhoneRequest, getPhone } from 'utils/phone';

export const personValidationSchema = Yup.object().shape({
  birthdate: getYupBirthDate().required(messageFieldRequired),
  firstname: yupFieldFirstName.required(messageFieldRequired),
  lastname: yupFieldLastName.required(messageFieldRequired),
  nni: yupNni,
  cpamId: yupCpamId,
  civility: Yup.string().required(messageFieldRequired),
  birthOrder: yupBirthOrder,
  email: yupEmail,
  phone: yupFieldPhone,
  landline: yupFieldPhone,
  address: Yup.object().shape({
    addressLine1: yupAddressLine1,
    addressLine2: yupAddressLine2,
    addressLine3: yupAddressLine3,
    city: yupCity,
    postalCode: yupFieldPostalCode,
  }),
});

export const personFormValuesToRequest = (
  values: PersonFormValues,
): PersonRequest => {
  let address: Person['address'] | undefined = undefined;
  const addressValues = values.address;
  if (
    addressValues.city ||
    addressValues.addressLine1 ||
    addressValues.addressLine2 ||
    addressValues.addressLine3 ||
    addressValues.postalCode
  ) {
    address = {
      city: addressValues.city || undefined,
      addressLine1: addressValues.addressLine1 || undefined,
      addressLine2: addressValues.addressLine2 || undefined,
      addressLine3: addressValues.addressLine3 || undefined,
      postalCode: addressValues.postalCode || undefined,
      country: addressValues.country || undefined,
    };
  }
  return {
    firstname: values.firstname,
    lastname: values.lastname,
    phone: values.phone && formatPhoneRequest(values.phone),
    landline: values.landline && formatPhoneRequest(values.landline),
    email: values.email || undefined,
    nni: values.nni || undefined,
    cpamId: values.cpamId || undefined,
    civility: values.civility!,
    birthdate: values.birthdate!,
    regimeCode: values.regime || undefined,
    birthOrder: values.birthOrder ? Number(values.birthOrder) : undefined,
    address,
  };
};

export const personToInitialFormValues = (person: Person): PersonFormValues => {
  return {
    address: {
      addressLine1: person.address?.addressLine1 || '',
      addressLine2: person.address?.addressLine2 || '',
      addressLine3: person.address?.addressLine3 || '',
      postalCode: person.address?.postalCode || '',
      city: person.address?.city || '',
      country: person.address?.country || 'fr',
    },
    birthOrder: person.birthOrder?.toString() || '',
    regime: person.regimeCode || '',
    civility: person.civility || null,
    nni: person.nni || '',
    cpamId: person.cpamId || '',
    email: person.email || '',
    birthdate: person.birthdate || null,
    firstname: person.firstname || '',
    lastname: person.lastname || '',
    phone: getPhone(person.phone),
    landline: getPhone(person.landline),
  };
};
