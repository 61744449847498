import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { TablePagination } from 'components/Table/TablePagination';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { LoadingTableRows } from 'components/Table/LoadingTableRows';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { Pagination } from 'models/Page.models';
import { FormikObserver } from 'utils/formik/FormikObserver';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import {
  canBeCancel,
  ProjectSearchCriteriaRequest,
} from 'models/oav/Project.models';
import { TableRowLink } from 'components/Table/TableRowLink';
import { getFirstStep, projectStepsData } from 'models/oav/ProjectStep.models';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import Stack from '@mui/material/Stack';
import { ProjectFormData } from './ProjectFilter';
import { useDebouncedCallback } from 'use-debounce';
import { TableLinearLoading } from 'components/Table/TableLinearLoading';
import { ChipProjectStatus } from 'components/Chip/ChipProjectStatus';
import { projectStatusData } from 'models/oav/ProjectStatus.models';
import {
  getStepsByPhases,
  projectPhasesData,
} from 'models/oav/ProjectPhase.models';
import Box from '@mui/material/Box';
import { getSegmentLabelsBySubscriptions } from 'utils/segment/label';
import { OfferType } from '../../models/referentiels/Offer.model';
import { DialogCancelProject } from 'content/project/DialogCancelProject';
import LockIcon from '@mui/icons-material/Lock';

enum SortEnum {
  DATE,
  PERSON,
}

const sortData: Record<SortEnum, string[]> = {
  [SortEnum.DATE]: ['techDateModification'],
  [SortEnum.PERSON]: ['prospect.lastname', 'prospect.firstname'],
};

interface Sort {
  by: SortEnum;
  order: '+' | '-';
}

const noValueLabel = 'Non renseigné';

interface ProjectTableProps {
  onContractFetched: (total: number) => void;
}

export const ProjectTable: React.FC<ProjectTableProps> = ({
  onContractFetched,
}) => {
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
  });

  const [sort, setSort] = useState<Sort>({
    by: SortEnum.DATE,
    order: '-',
  });

  const [projectSearchCriteriaRequest, setProjectSearchCriteriaRequest] =
    useState<ProjectSearchCriteriaRequest>({
      fromDate: null,
      inOffers: [],
      inSegments: [],
      toDate: null,
      search: null,
      globalView: false,
    });

  const [cancelDialogProjectId, setCancelDialogProjectId] = useState<
    string | undefined
  >(undefined);

  const onFilterChange = useDebouncedCallback(
    (searchCriteria: ProjectFormData) => {
      setProjectSearchCriteriaRequest({
        search: searchCriteria.search,
        inSegments: searchCriteria.segments.map(s => s.code),
        inOffers: searchCriteria.offers.map(o => o.code),
        inSteps: getStepsByPhases(searchCriteria.phases.map(([key]) => key)),
        inStatuses: searchCriteria.statuses.map(([key]) => key),
        fromDate:
          searchCriteria.fromDate && searchCriteria.fromDate.isValid()
            ? searchCriteria.fromDate.toDate()
            : null,
        toDate:
          searchCriteria.toDate && searchCriteria.toDate.isValid()
            ? searchCriteria.toDate.toDate()
            : null,
        globalView: searchCriteria.globalView,
      });

      setPagination(p => ({ ...p, page: 0 }));
    },
    250,
  );

  const { data, error, isFetching } = useQuery(
    projectQueries.getPage(
      pagination,
      projectSearchCriteriaRequest,
      sortData[sort.by].map(idColumn => sort.order + idColumn),
    ),
  );

  useEffect(() => {
    onContractFetched(data?.totalElements || 0);
  }, [data]);

  const { data: offerList } = useQuery(
    offerQueries.getAll({
      inTypes: [OfferType.BASE, OfferType.OPTION],
    }),
  );

  const offerMap = useMemo(
    () =>
      offerList
        ? new Map(offerList.map(offer => [offer.code, offer]))
        : undefined,
    [offerList],
  );

  const loading = data === undefined;

  const handleSort = (column: SortEnum) => {
    if (sort.by == column) {
      setSort({ ...sort, order: sort.order === '+' ? '-' : '+' });
    } else {
      setSort({ by: column, order: '+' });
    }
  };

  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination(() => ({ page: 0, size: parseInt(e.target.value) }));
  };
  const handlePageChange = (_: unknown, newPage: number) => {
    setPagination(p => ({ ...p, page: newPage }));
  };

  return (
    <>
      <TableContainer sx={{ width: '100%' }}>
        <FormikObserver onChange={onFilterChange} />
        {error ? (
          <ErrorMessage message="Une erreur est survenu lors de la récupération des projets." />
        ) : (
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow sx={{ position: 'relative' }}>
                <TableCell>
                  {isFetching && <TableLinearLoading />}
                  <TableSortLabel
                    active={sort.by === SortEnum.DATE}
                    direction={
                      sort.order === '+' || sort.by !== SortEnum.DATE
                        ? 'asc'
                        : 'desc'
                    }
                    onClick={() => handleSort(SortEnum.DATE)}
                    sx={{ '.MuiTableSortLabel-icon': { opacity: 0.3 } }}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>Segment</TableCell>
                <TableCell>Offre</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort.by === SortEnum.PERSON}
                    direction={
                      sort.order === '+' || sort.by !== SortEnum.PERSON
                        ? 'asc'
                        : 'desc'
                    }
                    onClick={() => handleSort(SortEnum.PERSON)}
                    sx={{ '.MuiTableSortLabel-icon': { opacity: 0.3 } }}
                  >
                    Personne
                  </TableSortLabel>
                </TableCell>
                <TableCell>Phase</TableCell>
                <TableCell>Etat</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <LoadingTableRows rowSize={pagination.size!} cellSize={6} />
              ) : data?.totalElements != 0 ? (
                data?.content.map((project, idx) => {
                  const to = `/projets/${project.id}`;
                  return (
                    <TableRowLink
                      to={to}
                      key={`entry-${idx}`}
                      sx={{ marginBottom: 2 }}
                    >
                      <TableCell
                        sx={{
                          textOverflow: 'ellipsis',
                          borderTopLeftRadius: '3rem',
                          borderBottomLeftRadius: '3rem',
                        }}
                      >
                        <Stack
                          sx={{
                            paddingLeft: '1rem',
                            borderRightColor: project.status
                              ? projectStatusData[project.status].color
                              : 'none',
                            borderRightWidth: 3,
                            borderRightStyle: 'solid',
                          }}
                        >
                          <Box sx={{ fontWeight: 'bold' }}>
                            {dayjs(project.techDateModification).format(
                              'DD MMM YYYY',
                            )}
                          </Box>
                          <Box>
                            {dayjs(project.techDateModification).format(
                              'HH:mm',
                            )}
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        {getSegmentLabelsBySubscriptions(
                          project.subscriptions,
                          offerMap,
                          noValueLabel,
                        )}
                      </TableCell>
                      <TableCell>
                        {project.subscriptions && offerMap
                          ? project.subscriptions.map(s =>
                              offerMap!.has(s.offerCode) ? (
                                <Box key={s.offerCode}>
                                  {offerMap!.get(s.offerCode)!.label}
                                </Box>
                              ) : (
                                noValueLabel
                              ),
                            )
                          : noValueLabel}
                      </TableCell>
                      <TableCell>
                        {project.subscriber.person.civility
                          ? project.subscriber.person.civility +
                            ' ' +
                            project.subscriber.person.lastname +
                            ' ' +
                            project.subscriber.person.firstname +
                            ' '
                          : noValueLabel}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        {project.currentStep &&
                        projectStepsData[project.currentStep] != null
                          ? projectPhasesData[
                              projectStepsData[project.currentStep]!.phase
                            ].label
                          : noValueLabel}
                      </TableCell>
                      <TableCell>
                        {project.status && (
                          <ChipProjectStatus projectStatus={project.status} />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          textOverflow: 'ellipsis',
                          borderTopRightRadius: '3rem',
                          borderBottomRightRadius: '3rem',
                        }}
                      >
                        {canBeCancel(project) && (
                          <LockIcon
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              setCancelDialogProjectId(project.id);
                            }}
                            color="error"
                          />
                        )}
                      </TableCell>
                    </TableRowLink>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{ opacity: 0.8, textAlign: 'center' }}
                  >
                    Aucun projet trouvé
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  rowsPerPageOptions={[10, 25, 50]}
                  count={data?.totalElements ?? 0}
                  rowsPerPage={pagination.size!}
                  page={pagination.page!}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  sx={{
                    '.MuiTablePagination-spacer': { display: 'none' },
                    '.MuiTablePagination-toolbar': {
                      margin: 'auto',
                      width: 'max-content',
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <DialogCancelProject
        open={cancelDialogProjectId != null}
        projectId={cancelDialogProjectId}
        onCancel={() => setCancelDialogProjectId(undefined)}
        onSuccess={() => setCancelDialogProjectId(undefined)}
      ></DialogCancelProject>
    </>
  );
};
