import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ChipPeriod from 'content/solution/ChipPeriod';
import PriceNumber from 'content/formula/PriceNumber';

import { formatDate } from 'utils/date';

import { BeneficiaryType } from 'models/oav/Beneficiary.models';
import { Person } from 'models/oav/Person.models';
import { Project } from 'models/oav/Project.models';
import {
  Solution,
  SolutionItem,
  SolutionPricing,
  TarificationPeriod,
} from 'models/oav/Solution.models';
import { Subscription } from 'models/oav/Subscription.models';
import { Offer } from 'models/referentiels/Offer.model';

type SummarySectionProps = {
  label: string;
  action?: JSX.Element;
  children: JSX.Element;
};

export const getSolutionItems = (
  items?: SolutionItem[],
  offers?: Offer[],
): SolutionItem[] => {
  let result = (offers || []).map(_ => {
    const matchingItem = items?.find(item => item.offer.code === _.code);

    return {
      offer: matchingItem?.offer || {
        code: _.code,
        label: _.label,
        description: _.description,
      },
      formula: matchingItem?.formula,
      pricing: matchingItem?.pricing,
    };
  });

  result = result.concat(
    (items || [])
      .filter(_ => !result.some(element => element.offer.code === _.offer.code))
      .map(_ => {
        return {
          offer: _.offer,
          formula: _.formula,
          pricing: _?.pricing,
        };
      }),
  );

  return result;
};

const SummarySection = (props: SummarySectionProps) => {
  return (
    <Stack spacing={0.5}>
      <Stack direction="row">
        <Typography variant="caption" flexGrow={1}>
          {props.label}
        </Typography>
        {props.action}
      </Stack>
      {props.children}
    </Stack>
  );
};

type BoxPersonProps = {
  person: Person;
};

const BoxPerson = (props: BoxPersonProps) => {
  return (
    <Stack
      columnGap={0.5}
      alignItems="center"
      sx={{
        flexDirection: 'row',
        '@container (max-width: 250px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }}
    >
      <Typography
        flexGrow={1}
        noWrap
        sx={{
          width: '100%',
          overflowY: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.person.lastname} {props.person.firstname}
      </Typography>
      {props.person.birthdate && (
        <Typography variant="caption">
          <pre>{formatDate(props.person.birthdate)}</pre>
        </Typography>
      )}
    </Stack>
  );
};
type BoxPricingProps = {
  label: string;
  color?: string;
  pricing?: SolutionPricing;
  period?: TarificationPeriod;
};

const BoxPricing = (props: BoxPricingProps) => {
  const period = props.period || TarificationPeriod.MONTHLY;

  return (
    <Stack
      alignItems="center"
      sx={{
        flexDirection: 'row',
        '@container (max-width: 250px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }}
    >
      <Typography
        flexGrow={1}
        noWrap
        sx={{
          width: '100%',
          overflowY: 'hidden',
          textOverflow: 'ellipsis',
          color: props.color,
        }}
      >
        {props.label || ''}
      </Typography>
      <Typography
        component="div"
        variant="caption"
        noWrap
        sx={{
          width: '100%',
          textAlign: 'right',
          '@container (max-width: 250px)': {
            textAlign: 'left',
          },
        }}
      >
        <PriceNumber
          price={
            period === TarificationPeriod.ANNUALLY
              ? props.pricing?.perYear
              : props.pricing?.perMonth
          }
        />
      </Typography>
    </Stack>
  );
};

type BoxSolutionItemProps = {
  item: SolutionItem;
  period?: TarificationPeriod;
};

const BoxSolutionItem = (props: BoxSolutionItemProps) => {
  const period = props.period || TarificationPeriod.MONTHLY;

  return (
    <Box>
      <Typography
        component="div"
        variant="caption"
        noWrap
        sx={{
          width: '100%',
          overflowY: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.item.offer.label}
      </Typography>
      <BoxPricing
        label={props.item.formula?.label || '-'}
        color={props.item.formula?.color}
        pricing={props.item.pricing}
        period={period}
      />
    </Box>
  );
};

export type SubscriptionSummaryProps = {
  project: Project;
  subscription: Subscription;
  solution?: Solution;
  offer: Offer;
  divider?: JSX.Element;
  showContract?: boolean;
  showDate?: boolean;
  showBeneficiaries?: boolean;
  showSolution?: boolean;
};

const SubscriptionSummary = (props: SubscriptionSummaryProps) => {
  const theme = useTheme();

  const divider = props.divider || <Divider />;

  const benefSubscriber = props.subscription.beneficiaries?.find(
    _ => _.type === BeneficiaryType.SUBSCRIBER,
  );
  const benefPartner = props.subscription.beneficiaries?.find(
    _ => _.type === BeneficiaryType.PARTNER,
  );
  const benefChildren = props.subscription.beneficiaries?.filter(
    _ => _.type === BeneficiaryType.CHILD,
  );

  const [period, setPeriod] = useState<TarificationPeriod>(
    TarificationPeriod.MONTHLY,
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{
        containerType: 'inline-size',
      }}
    >
      {props.showContract && (
        <>
          <Grid item xs={12}>
            <SummarySection label="📜 Contrat">
              <Typography>
                {props.subscription.contractCode ? (
                  <pre style={{ display: 'inline-flex' }}>
                    {props.subscription.contractCode}
                  </pre>
                ) : (
                  '-'
                )}
              </Typography>
            </SummarySection>
          </Grid>

          <Grid item xs={12}>
            {divider}
          </Grid>
        </>
      )}

      {props.showDate && (
        <>
          <Grid item xs={12}>
            <SummarySection label="📅 Date d'effet">
              <Typography>
                {formatDate(props.subscription.dateStart)}
              </Typography>
            </SummarySection>
          </Grid>

          <Grid item xs={12}>
            {divider}
          </Grid>
        </>
      )}

      {props.showBeneficiaries && (
        <>
          <Grid item xs={12}>
            <SummarySection label="👨‍👩‍👧 Bénéficiaires">
              <Stack spacing={1}>
                {benefSubscriber && (
                  <BoxPerson person={benefSubscriber.person} />
                )}

                {benefPartner && (
                  <Box>
                    <Typography variant="caption">Conjoint</Typography>
                    <BoxPerson person={benefPartner.person} />
                  </Box>
                )}

                {benefChildren && benefChildren.length > 0 && (
                  <Box>
                    <Typography variant="caption">Enfant(s)</Typography>
                    {benefChildren.map(_ => (
                      <BoxPerson key={_.id} person={_.person} />
                    ))}
                  </Box>
                )}
              </Stack>
            </SummarySection>
          </Grid>

          <Grid item xs={12}>
            {divider}
          </Grid>
        </>
      )}

      {props.showSolution && (
        <>
          <Grid item xs={12}>
            <SummarySection
              label={`🧮 Solution${props.solution?.order !== undefined ? ` n°${props.solution?.order}` : ''}`}
              action={<ChipPeriod period={period} onChange={setPeriod} />}
            >
              {!props.solution ? (
                <Typography>-</Typography>
              ) : (
                <Stack spacing={1}>
                  <BoxSolutionItem item={props.solution.base} period={period} />

                  {getSolutionItems(
                    props.solution.reinforcements,
                    props.offer.reinforcements,
                  ).map(_ => (
                    <BoxSolutionItem
                      key={_.offer.code}
                      item={_}
                      period={period}
                    />
                  ))}

                  {getSolutionItems(
                    props.solution.options,
                    props.offer.options,
                  ).map(_ => (
                    <BoxSolutionItem
                      key={_.offer.code}
                      item={_}
                      period={period}
                    />
                  ))}

                  <BoxPricing
                    label="Total"
                    pricing={props.solution.pricing}
                    period={period}
                  />
                </Stack>
              )}
            </SummarySection>
          </Grid>

          <Grid item xs={12}>
            {divider}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SubscriptionSummary;
