import React from 'react';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from 'components/TextField/TextField';
import { AddressFrom } from 'content/form/AddressForm';
import { PersonFormValues } from 'models/Form.model';
import FormCard from 'content/form/FormCard';
import { Icon } from 'components/Icon';
import { TextFieldPhone } from 'components/TextField/TextFieldPhone/TextFieldPhone';

const ContactInfoForm = () => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<PersonFormValues>();

  return (
    <FormCard
      icon={props => (
        <Icon {...props}>
          <PlaceOutlinedIcon sx={{ width: 30, height: 30 }} />
        </Icon>
      )}
      label="Coordonnées"
    >
      <Grid container spacing={{ sm: 2, xs: 1 }}>
        <AddressFrom />
        <Grid item xs={6}>
          <TextFieldPhone
            fullWidth
            label="Téléphone mobile"
            value={values.phone?.number}
            countryCode={values.phone?.countryCode}
            name="phone"
            error={touched.phone && Boolean(errors.phone)}
            onChange={(value, country) => {
              setFieldValue('phone', {
                number: value,
                countryCode: country,
              });
            }}
          />
          {touched.phone && errors.phone && (
            <FormHelperText error>{errors.phone}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextFieldPhone
            fullWidth
            label="Téléphone fixe"
            value={values.landline?.number}
            countryCode={values.landline?.countryCode}
            name="landline"
            error={touched.landline && Boolean(errors.landline)}
            onChange={(value, country) => {
              setFieldValue('landline', {
                number: value,
                countryCode: country,
              });
            }}
          />
          {touched.landline && errors.landline && (
            <FormHelperText error>{errors.landline}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            value={values.email}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{ minLength: 1, maxLength: 100 }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ContactInfoForm;
