import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import AppBar from 'components/AppBar';
import { Formik } from 'formik';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  ProjectFilter,
  ProjectFormData,
  validationSchema,
} from 'pages/project/ProjectFilter';
import { ProjectTable } from 'pages/project/ProjectTable';
import { InputAdornment, TextField } from '@mui/material';
import { SearchIcon } from 'components/Icon/SearchIcon';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useRole } from 'utils/hooks/useRole';
import { UserRole } from 'models/UserInfo.model';

const ProjectsPage: React.FC = () => {
  document.title = `Projets - ${appName}`;

  const theme = useTheme();

  const [total, setTotal] = useState(0);
  const hasGlobalAccessRole = useRole(UserRole.ALL_PROJECTS_ACCESS);

  return (
    <AppBar>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Formik<ProjectFormData>
          initialValues={{
            offers: [],
            segments: [],
            phases: [],
            statuses: [],
            toDate: null,
            fromDate: null,
            search: '',
            globalView: false,
          }}
          onSubmit={() => {}}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({ values, handleChange, touched, handleBlur, errors }) => (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                flexWrap="wrap"
                useFlexGap
                gap={2}
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="h2">Projets</Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  name="search"
                  value={values.search}
                  error={touched.search && !!errors.search}
                  helperText={touched.search && errors.search}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Recherche"
                  sx={{
                    maxWidth: '500px',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color={theme.palette.text.secondary} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Link to="/projets/type">
                  <Button size="medium" variant="gradient" fullWidth>
                    Nouveau Projet
                  </Button>
                </Link>
              </Stack>
              <Stack>
                <Box sx={{ marginBottom: 5 }}>
                  <ProjectFilter
                    nbProject={total}
                    displayGlobalViewFilter={hasGlobalAccessRole}
                  />
                </Box>
                <ProjectTable onContractFetched={setTotal} />
              </Stack>
            </>
          )}
        </Formik>
      </Box>
    </AppBar>
  );
};

export default ProjectsPage;
