import React from 'react';
import { Grid } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';
import { Person } from 'models/oav/Person.models';
import FormCard from 'content/form/FormCard';
import { Icon } from 'components/Icon';
import { formatPhoneDisplay } from 'utils/phone';

interface ContactInfoViewCardProps {
  person: Person;
}

export const ContactInfoViewCard = (props: ContactInfoViewCardProps) => (
  <FormCard
    icon={props => (
      <Icon {...props}>
        <PlaceOutlinedIcon sx={{ width: 30, height: 30 }} />
      </Icon>
    )}
    label="Coordonnées"
  >
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FieldReadOnly
          label="N° et nom de voie"
          value={props.person.address?.addressLine1}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Complément d'adresse 1"
          value={props.person.address?.addressLine2}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Complément d'adresse 2"
          value={props.person.address?.addressLine3}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Code postal"
          value={props.person.address?.postalCode}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly label="Ville" value={props.person.address?.city} />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Téléphone mobile"
          value={formatPhoneDisplay(props.person.phone)}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldReadOnly
          label="Téléphone fixe"
          value={formatPhoneDisplay(props.person.landline)}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldReadOnly label="E-mail" value={props.person.email} />
      </Grid>
    </Grid>
  </FormCard>
);
