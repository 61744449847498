import { Agency } from 'models/UserInfo.model';
import { UserUpdateRequest } from 'models/authentication/user';
import { getRequest, putRequest } from 'utils/queries/queries.utils';

const appUrl = process.env.REACT_APP_API_URI_BASE;

export const getAgency = (
  agencyId: string,
  token?: string,
): Promise<Agency> => {
  return fetch(`${appUrl}/authentication/oav/user/agency/${agencyId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(_ => _.json());
};

export const updateUserInfo = async (
  userUpdateRequest: UserUpdateRequest,
  token: string,
): Promise<void> => {
  const url = `${appUrl}/authentication/oav/user/update`;

  return await putRequest(url, userUpdateRequest, token);
};

export const signinToHenner = async (accessToken: string): Promise<string> => {
  const url = `${appUrl}/authentication/oav/henner/signin`;
  return (await getRequest(url, accessToken)).redirectUri;
};
