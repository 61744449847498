import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  MutationGenerator,
  useAccessToken,
} from 'utils/api/api';
import {
  fetchSubscriptionSolution,
  fetchSubscriptionSolutions,
  fetchSubscriptionTarification,
  updateBankDetails,
  updatePromotions,
} from './Subscription.api';
import {
  BankDetailsRequest,
  Subscription, SubscriptionPromotionRequest,
} from 'models/oav/Subscription.models';

export const subscriptionQueries = {
  getByIdTarification: (projectId: string, id: string) =>
    subscriptionGetByIdTarificationOptions(projectId, id),
  getByIdSolution: (projectId: string, id: string) =>
    subscriptionGetByIdSolutionOptions(projectId, id),
  getByIdSolutions: (projectId: string, id: string) =>
    subscriptionGetByIdSolutionsOptions(projectId, id),
};

export const subscriptionGetByIdTarificationOptions = (
  projectId: string,
  id: string,
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'subscription', id, 'tarification'],
    queryFn: () => fetchSubscriptionTarification(token, projectId, id),
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });
};

export const subscriptionGetByIdSolutionOptions = (
  projectId: string,
  id: string,
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'subscription', id, 'solution'],
    queryFn: () => fetchSubscriptionSolution(token, projectId, id),
  });
};

export const subscriptionGetByIdSolutionsOptions = (
  projectId: string,
  id: string,
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'subscription', id, 'solutions'],
    queryFn: () => fetchSubscriptionSolutions(token, projectId, id),
  });
};

const useUpdateBankDetails: MutationGenerator<
  Subscription,
  {
    projectId: string;
    subscriptionId: string;
    payload: BankDetailsRequest;
  }
> = ({ onSuccess, onError }) => {
  const accessToken = useAccessToken();
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, subscriptionId, payload }) =>
      updateBankDetails(accessToken, projectId, subscriptionId, payload),
    onSuccess: (data, variables, context) => {
      client.invalidateQueries({
        queryKey: ['projects', variables.projectId],
      });

      onSuccess?.(data, variables, context);
    },
    onError: (error: Error, variables, context) => {
      onError?.(error, variables, context);
    },
  });
};

const updateSubscriptionPromotionsMutation = (
  projectId: string,
  subscriptionId: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (project: Subscription) => void;
    onError?: (err: Error) => void;
  },
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: SubscriptionPromotionRequest) =>
      updatePromotions(projectId, subscriptionId, payload, accessToken),
    onSuccess: (subscription: Subscription) => {
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });
      onSuccess?.(subscription);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};


export const subscriptionMutations = {
  useUpdateBankDetails: useUpdateBankDetails,
  updateSubscriptionPromotionsMutation: updateSubscriptionPromotionsMutation
};

