import React, { useRef } from 'react';
import { useUserContext } from 'components/Context/UserContext';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';
import { Formik, FormikProps } from 'formik';
import Button from '@mui/material/Button';
import TextField from 'components/TextField/TextField';
import { authenticationMutations } from 'api/authentication/AuthenticationQuery.api';
import { useAppContext } from 'components/Context/AppContext';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';

interface FormUserInfo {
  title?: string;
}

const AccountMePage: React.FC = () => {
  const { addSnackbar } = useAppContext();

  const user = useUserContext();
  const theme = useTheme();

  const formikRef = useRef<FormikProps<FormUserInfo>>(null);

  const mutation = authenticationMutations.updateUserInfo({
    onSuccess: () => {
      user.reload();
    },
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  const handleSubmit = (values: FormUserInfo) => {
    mutation.mutate(values);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%', p: 4, my: 2, border: 1 }}>
            <Grid
              container
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
            >
              <Grid item xs={2}>
                <Avatar
                  alt={user.data.user?.name}
                  src={user.data.user?.pictureUri}
                  sx={{
                    border: 0,
                    borderColor: theme.palette.primary.main,
                    width: 128,
                    height: 128,
                  }}
                />
              </Grid>
              <Grid item xs={10} p={2}>
                <Typography variant="h3" sx={{ py: 2 }}>
                  {user.data.user?.name}
                </Typography>
                <Typography>{user.data.agency?.companyName}</Typography>
                <Typography>{user.data.agency?.name}</Typography>
                <Typography>
                  Apporteur n°{user.data.user?.codeApporteur}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {user.data.user != null && (
          <Formik
            innerRef={formikRef}
            initialValues={user.data.user}
            onSubmit={values => {
              return handleSubmit(values);
            }}
          >
            {({ values, handleChange }) => (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={'Titre'}
                  name="title"
                  id="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </Formik>
        )}
        <Grid item xs={12}>
          <FieldReadOnly
            label={'Email de contact'}
            value={user.data.agency?.contactEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldReadOnly
            label={'Téléphone de contact'}
            value={user.data.agency?.contactPhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            disabled={mutation.isPending}
            onClick={() => {
              if (formikRef.current?.values) formikRef.current.handleSubmit();
            }}
          >
            Sauvegarder
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountMePage;
