import React, { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

import { appName } from 'App';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import { useAppContext } from 'components/Context/AppContext';
import { useQuery } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { Form, Formik, FormikProps } from 'formik';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import { FormProjectAddressDetails } from 'content/form/FormProjectAddressDetails';
import { AddressFormValues } from 'models/Form.model';
import { messageFieldRequired } from 'utils/validation/message';
import {
  yupAddressLine1,
  yupAddressLine2,
  yupAddressLine3,
  yupCity,
} from 'utils/validation/yupAddress';
import { isBadRequestWithDetailsError } from 'utils/api/api';
import {
  ProjectStepAddressDetails,
  ProjectStepAddressDetailsErrors,
} from 'models/oav/Project.models';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { emptyStringToUndefined } from 'utils/fields/fields';
import { projectStepMutations } from 'api/oav/ProjectStepQuery.api';

const CURRENT_STEP = ProjectStep.ADDRESS_DETAILS;

export interface FormAddressDetailsValues extends AddressFormValues {}

const validationSchema = Yup.object().shape({
  addressLine1: yupAddressLine1.required(messageFieldRequired),
  addressLine2: yupAddressLine2,
  addressLine3: yupAddressLine3,
  city: yupCity.required(messageFieldRequired),
});

const ProjectAddressDetailsPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!id) return <></>;

  const { addSnackbar } = useAppContext();

  const projectQuery = useQuery(projectQueries.getById(id));

  const formikRef = useRef<FormikProps<FormAddressDetailsValues>>(null);

  const initialValues = useMemo((): FormAddressDetailsValues | undefined => {
    if (!projectQuery.data) return undefined;

    const address = projectQuery.data.subscriber.person.address;
    return {
      addressLine1: address?.addressLine1 || '',
      addressLine2: address?.addressLine2 || '',
      addressLine3: address?.addressLine2 || '',
      postalCode: address?.postalCode || '',
      country: 'fr',
      city: address?.city || '',
    };
  }, [projectQuery.data]);

  const { mutate, isPending } =
    projectStepMutations.updateProjectStepAddressDetails(id, {
      onSuccess: () => {
        navigateToNextStep(CURRENT_STEP, id, navigate);
      },
      onError: (error: Error) => {
        if (
          isBadRequestWithDetailsError<ProjectStepAddressDetailsErrors>(
            error,
          ) &&
          error.response?.details
        ) {
          onBadRequest(error.response?.details);
        } else {
          addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
        }
      },
    });

  const onBadRequest = (errorDetails: ProjectStepAddressDetailsErrors) => {
    if (errorDetails.subscriber) {
      Object.entries(errorDetails.subscriber).forEach(([key, value]) => {
        formikRef.current?.setFieldError(key, value);
        formikRef.current?.setFieldTouched(key, true, false);
      });
    }
  };

  if (projectQuery.error) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={
            projectQuery.error instanceof RestError
              ? projectQuery.error.status
              : undefined
          }
        />
      </Grid>
    );
  }

  if (!projectQuery.data || !initialValues) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const handleSubmit = (values: FormAddressDetailsValues) => {
    const request: ProjectStepAddressDetails = {
      subscriber: {
        ...emptyStringToUndefined(values),
      },
    };
    mutate(request);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <Form>
            <Grid container justifyContent="center" alignItems="center" gap={2}>
              <Grid item xs={12}>
                <Typography variant="h3">Coordonnées postales</Typography>
              </Grid>

              <Grid item xs={12}>
                <Card
                  sx={{
                    p: 2,
                    border: 2,
                    borderColor: theme.palette.divider,
                  }}
                >
                  <FormProjectAddressDetails />
                </Card>
              </Grid>

              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  color="default"
                  disabled={isPending}
                  onClick={() =>
                    navigateToPreviousStep(CURRENT_STEP, id, navigate)
                  }
                  sx={{ px: 4 }}
                >
                  Retour
                </Button>
              </Grid>

              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  disabled={isPending}
                  sx={{ px: 4 }}
                >
                  {isPending ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    'Valider'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectAddressDetailsPage;
