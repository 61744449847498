import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import { TrfDisclaimer } from 'models/referentiels/Tarification.models';
import { SolutionTarificationDisclaimer } from 'models/oav/Solution.models';

type TarificationDisclamerProps = {
  disclamers?: TrfDisclaimer | SolutionTarificationDisclaimer;
};

export const TarificationDisclaimers: FC<TarificationDisclamerProps> = ({
  disclamers,
}) => {
  const validityDisclaimers =
    (disclamers as TrfDisclaimer)?.tarificationValidity ||
    (disclamers as SolutionTarificationDisclaimer)?.validity;

  return disclamers != null ? (
    <Typography variant="body2" component="div" color="warning.main">
      {validityDisclaimers}
    </Typography>
  ) : (
    <Typography variant="body2" component="div">
      Les tarifs présentés tiennent compte de l’évolution des cotisations
      effectuée à effet du 1er janvier {new Date().getFullYear() + 1}.
    </Typography>
  );
};
