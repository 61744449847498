import { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
  MenuItem,
  Select as SelectMui,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useRef } from 'react';
import Stack from '@mui/material/Stack';
import FormattedTextField from 'components/TextField/formatted/FormattedTextField';
import { CountryCode, PhoneCountry } from 'models/Phone.models';
import { findCountry, phoneCountriesMap, removeCountryCode } from 'utils/phone';

export type TextFieldPhoneProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string, countryCode: CountryCode) => void;
  countryCode?: CountryCode;
};

const phoneCountries: PhoneCountry[] = [
  phoneCountriesMap[CountryCode.DE],
  phoneCountriesMap[CountryCode.AD],
  phoneCountriesMap[CountryCode.BE],
  phoneCountriesMap[CountryCode.FR],
  phoneCountriesMap[CountryCode.IT],
  phoneCountriesMap[CountryCode.LU],
  phoneCountriesMap[CountryCode.MC],
  phoneCountriesMap[CountryCode.CH],
];

const removeRegex = /\D/g;
const separator = ' ';

const toRawFormat = (value: string, country: PhoneCountry) => {
  const computedValue = value
    .replaceAll(separator, '')
    .replaceAll(removeRegex, '');

  return (
    country.patternRegex
      .exec(computedValue)
      ?.reduce((formatted, value, index) => {
        if (index === 0) {
          return '';
        }
        if (value === '') {
          return formatted;
        }
        return `${formatted}${value}`;
      }, '')
      .trim() || ''
  );
};

export const TextFieldPhone = (props: TextFieldPhoneProps) => {
  const theme = useTheme();

  const country =
    phoneCountriesMap[props.countryCode ? props.countryCode : CountryCode.FR];
  const selectedCountry = useRef<PhoneCountry>(country);
  selectedCountry.current =
    phoneCountriesMap[props.countryCode ? props.countryCode : CountryCode.FR];

  const labelMargin =
    selectedCountry.current.dialCode.length == 3 ? '80px' : '70px';

  const transform = useCallback(
    // Extract dial code in case of copy and paste
    (value: string) => {
      let computedValue = value;
      if (computedValue.length > 5) {
        // In case of copy and paste, detect country code and edit selected country
        const country = findCountry(computedValue);
        if (country) {
          // If country code found extract it of the phone number
          selectedCountry.current = country;
          computedValue = removeCountryCode(
            computedValue,
            selectedCountry.current,
          );
        }

        // Compute to raw format => needed to remove exceeded chars if present
        computedValue = toRawFormat(computedValue, selectedCountry.current);
      }

      return computedValue;
    },
    [selectedCountry],
  );
  return (
    <FormattedTextField
      {...props}
      groupsRegex={selectedCountry.current.patternRegex}
      removeRegex={removeRegex}
      separator={separator}
      transform={transform}
      inputProps={{
        ...props?.inputProps,
        minLength: 7,
        maxLength: 20,
      }}
      onChange={e => {
        props.onChange(e.target.value, selectedCountry.current.code);
      }}
      InputLabelProps={{
        sx: {
          pointerEvents: 'none',
          ml: labelMargin,
          '&.Mui-focused': {
            cursor: 'default',
            pointerEvents: 'all',
          },
        },
      }}
      InputProps={{
        sx: { '& .MuiInputBase-input': { pl: 0 } },
        startAdornment: (
          <InputAdornment position="start" variant="standard">
            <Stack direction="row" alignItems="center">
              <SelectMui
                value={selectedCountry.current.code}
                variant="standard"
                onChange={e => {
                  const country =
                    phoneCountriesMap[e.target.value as CountryCode];
                  const value = (props.value as string | undefined) ?? '';
                  // Compute to raw format => needed to remove exceeded chars if present
                  props.onChange(toRawFormat(value, country), country.code);
                }}
                sx={{
                  padding: 0,
                  background: 'transparent',
                  width: '33px',
                  height: '33px',
                  borderRadius: '50%',
                }}
                disableUnderline={true}
                renderValue={value =>
                  phoneCountriesMap[value as CountryCode].icon
                }
                inputProps={{
                  IconComponent: () => null,
                  sx: {
                    '&.MuiInput-input.MuiInput-input': { padding: 1 },
                  },
                }}
              >
                {phoneCountries.map(country => (
                  <MenuItem key={country.code} value={country.code}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ width: '100%' }}
                    >
                      {country.icon}
                      <Typography sx={{ paddingRight: 1 }}>
                        {country.label}
                      </Typography>
                      <pre style={{ marginLeft: 'auto' }}>
                        +{country.dialCode}
                      </pre>
                    </Stack>
                  </MenuItem>
                ))}
              </SelectMui>
              <Typography sx={{ color: theme.palette.text.primary }}>
                <pre>+{selectedCountry.current.dialCode}</pre>
              </Typography>
            </Stack>
          </InputAdornment>
        ),
      }}
    />
  );
};
