import { To } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ButtonLink, { ButtonLinkProps } from '../ButtonLink';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { Agency, UserInfo } from 'models/UserInfo.model';

export type ButtonLinkUserProps = Partial<ButtonLinkProps> & {
  user?: UserInfo;
  agency?: Agency;
  to?: To;
};

const ButtonLinkUser = (props: ButtonLinkUserProps) => {
  return (
    <ButtonLink
      {...props}
      label="Votre profil"
      icon={<ChevronRightIcon />}
      iconPosition="end"
      to={props.to}
    >
      <Box>
        <Typography component="div" fontWeight={props.selected ? 700 : 500}>
          {props.user?.name}
        </Typography>
        <Typography component="div" variant="caption">
          {props.agency?.companyName}
        </Typography>
        <Typography component="div" variant="caption">
          Apporteur n°{props.user?.codeApporteur}
        </Typography>
      </Box>
    </ButtonLink>
  );
};

export default ButtonLinkUser;
