import { Document } from './Document.model';
import {
  ContractualDocument,
  ContractualDocumentType,
} from 'models/oav/ContractualDocument.models';
import { StaticDocumentTypeCodes } from 'models/referentiels/Document.models';

export type CommercialProposition = {
  code: string;
  contractualDocuments: ContractualDocument[];
  signatureStatus: SignatureStatus;
  signedAt?: Date;
  signatureSentAt?: Date;
  signatureExpireAt?: Date;
  signatureMode?: SignatureMode;
} & Document;

export type CommercialPropositionRequest = {
  sendEmail: boolean;
  email?: string;
  staticDocuments?: StaticDocumentTypeCodes[];
};

export enum SignatureStatus {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  SIGNED = 'SIGNED',
}

export enum SignatureMode {
  MANUAL = 'MANUAL',
  DOCUSIGN = 'DOCUSIGN',
}

export type CommercialPropositionDocumentTypes = {
  contractualDocuments: { code: ContractualDocumentType; label: string }[];
  staticDocuments: { code: StaticDocumentTypeCodes; label: string }[];
};
