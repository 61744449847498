import {
  Beneficiary,
  BeneficiaryRequestPost,
  BeneficiaryRequestPostErrors,
} from 'models/oav/Beneficiary.models';
import { OfferNeedType, OfferNeedWeight } from 'models/offerNeed.models';
import {
  Civility,
  Person,
  PersonContactRequest,
  PersonRequest,
  PersonRequestPostErrors,
} from 'models/oav/Person.models';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import {
  Subscription,
  SubscriptionRequest,
} from 'models/oav/Subscription.models';
import { ProjectStatus } from 'models/oav/ProjectStatus.models';

export type Project = {
  id: string;
  techDateCreated: Date;
  techDateModification?: Date;
  intermediaryId: string;
  prospect: Person;
  subscriber: Beneficiary;
  partner?: Beneficiary;
  children?: Beneficiary[];
  subscriptions?: Subscription[];
  currentStep?: ProjectStep;
  draft?: boolean;
  needs?: ProjectNeeds;
  status?: ProjectStatus;
  indirect: boolean;
  platform?: string;
  source?: string;
  opportunityId?: string;
};

export type ProjectRequestPost = {
  dateStart?: Date;
  prospect?: PersonRequest;
  subscriber: BeneficiaryRequestPost;
  partner?: BeneficiaryRequestPost;
  children?: BeneficiaryRequestPost[];
  isIndirect?: boolean;
};

export type ProjectRequestPostErrors = {
  dateStart?: string;
  subscriber?: BeneficiaryRequestPostErrors;
  partner?: BeneficiaryRequestPostErrors;
  [key: string]: BeneficiaryRequestPostErrors | string | undefined;
};

// Step - Beneficiaries.
export type ProjectStepBeneficiaries = {
  subscriber: BeneficiaryRequestPost;
  partner?: BeneficiaryRequestPost;
  children?: BeneficiaryRequestPost[];
};

// Step - Subscriptions.
export type ProjectStepSubscriptions = {
  subscriptions: SubscriptionRequest[];
};

// Step - Contact.
export type ProjectStepContact = {
  subscriber: PersonContactRequest;
};

export type ProjectStepContactPostErrors = {
  subscriber?: PersonRequestPostErrors;
};

// Step - Needs.
export type ProjectNeeds = Partial<{
  [key in OfferNeedType]: OfferNeedWeight;
}>;

export type ProjectStepNeedsRequest = { needs?: ProjectNeeds };

// Step - Proposal.
export type ProjectStepProposal = {};

// Step - Solutions.
export type ProjectStepSolutions = {};

// Step - Clients information
export type ProjectStepClientsInfo = {
  subscriber: {
    phone?: string;
    landline?: string;
    email: string;
  };
  partner?: {
    id: string;
    civility: Civility;
    firstname: string;
    lastname: string;
  };
  children?: {
    id: string;
    civility: Civility;
    firstname: string;
    lastname: string;
  }[];
};

export type ProjectStepClientsInfoErrors = {
  subscriber: {
    phone?: string;
    landline?: string;
    email?: string;
  };
  partner?: {
    civility?: string;
    firstname?: string;
    lastname?: string;
  };
  [key: `children[${number}]`]:
    | {
        civility?: string;
        firstname?: string;
        lastname?: string;
      }
    | undefined;
};

// Step - Address details
export type ProjectStepAddressDetails = {
  subscriber: {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    postalCode: string;
    city: string;
    country: string;
  };
};

export type ProjectStepAddressDetailsErrors = {
  subscriber: {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    postalCode?: string;
    city?: string;
    country?: string;
  };
};

// Step - Solution selection
export type ProjectStepSolutionSelection = {
  subscriptions: {
    id: string;
    solutionId: string;
  }[];
};

export type SolutionSelectionErrors = {
  id?: string;
  solutionId?: string;
};

export type ProjectStepSolutionSelectionErrors = {
  [key: string]: SolutionSelectionErrors | string | undefined;
};

// Step - Contract
export type ProjectStepContract = {
  subscriptions?: {
    id: string;
    terminationMandate?: {
      contractNumber: string;
      businessName: string;
    };
  }[];
};

export interface ProjectSearchCriteriaRequest {
  fromDate?: Date | null;
  toDate?: Date | null;
  inSegments?: string[];
  inOffers?: string[];
  inStatuses?: ProjectStatus[];
  inSteps?: ProjectStep[];
  search?: string | null;
  globalView?: boolean;
}

export type ProjectStepContractValidation = {
  valid: boolean;
  errors: {
    [key: `children[${number}]`]:
      | ProjectStepContractValidationBeneficiaryErrors
      | undefined;
    partner?: ProjectStepContractValidationBeneficiaryErrors;
    subscriber?: ProjectStepContractValidationBeneficiaryErrors;
    [key: `subscriptions[${number}]`]:
      | {
          debitBankDetails?:
            | string
            | {
                iban?: string;
                rib?: string;
                accountHolder: string;
              };
          creditBankDetails?:
            | string
            | {
                iban?: string;
                rib?: string;
                accountHolder: string;
              };
          paymentDay?: string;
          paymentFrequency?: string;
        }
      | undefined;
  };
};

export type ProjectStepContractValidationBeneficiaryErrors = {
  remoteTransmission?: string;
  person?: {
    nni?: string;
    birthOrder?: string;
    cpamId?: string;
  };
};

// Step - Signature
export type ProjectSignatureRequest = {
  subscriberMail: string;
};

export const canBeCancel = (project?: Project): boolean => {
  if (project == null || project.currentStep == null) {
    return false;
  }

  return (
    project.status === ProjectStatus.PARTS_PENDING ||
    project.status == ProjectStatus.IN_PROGRESS
  );
};
