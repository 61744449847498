export const arrayToMap = <T>(
  array: T[],
  key: keyof T,
): { [key: string]: T } => {
  return array.reduce<{
    [key: string]: T;
  }>((prev, current) => {
    const keyValue = current[key] as string;
    prev[keyValue] = current;
    return prev;
  }, {});
};
