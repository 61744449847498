import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, Formik, FormikProps } from 'formik';
import { produce } from 'immer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useProjectContext } from 'components/Context/ProjectContext';
import { appName } from 'App';
import {
  getOfferNeedTypeKey,
  getOfferNeedWeightWithSubNeed,
  offerNeedDatas,
  OfferNeedType,
  OfferNeedWeight,
  offerNeedWeightLabels,
} from 'models/offerNeed.models';
import { FormikObserver } from 'utils/formik/FormikObserver';
import { ToggleButtonGroupSplit } from 'components/ToggleButton/ToggleButtonGroupSplit';
import { ToggleButtonSplit } from 'components/ToggleButton/ToggleButtonSplit';
import { Box, CircularProgress, Skeleton, Switch } from '@mui/material';
import { useAppContext } from 'components/Context/AppContext';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import { useQuery } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import { projectStepMutations } from 'api/oav/ProjectStepQuery.api';
import { Project, ProjectNeeds } from 'models/oav/Project.models';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';

const OfferNeedToggleButton = (props: { value: OfferNeedWeight }) => {
  return (
    <ToggleButtonSplit
      fullWidth
      value={props.value}
      color="primary"
      aria-label={offerNeedWeightLabels[props.value].label}
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      {offerNeedWeightLabels[props.value].label}
    </ToggleButtonSplit>
  );
};

const CURRENT_STEP = ProjectStep.NEEDS;

const ProjectNeedsPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return <></>;

  const theme = useTheme();
  const screenSizeDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { addSnackbar } = useAppContext();

  const { setDataCurrent } = useProjectContext();

  const [collectingNeed, isCollectingNeed] = useState<boolean>(true);

  const projectQuery = useQuery(projectQueries.getById(id));

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError) return 'error';
    if (projectQuery.isLoading || !projectQuery.data) return 'loading';
    return 'done';
  }, [projectQuery]);
  const needIsNotCollected = (project: Project) => {
    return project.needs == null && project.currentStep != CURRENT_STEP;
  };

  useEffect(() => {
    if (projectQuery.data == null) {
      return;
    }

    isCollectingNeed(!needIsNotCollected(projectQuery.data));
  }, [projectQuery.data]);

  const mutation = projectStepMutations.updateProjectStepNeeds(id, {
    onSuccess: () => {
      navigateToNextStep(CURRENT_STEP, id, navigate);
    },
    onError: (error: Error) => {
      if (error instanceof RestError && error.status === 400) {
        if (refForm.current && error.response.details?.needs) {
          Object.entries(error.response.details?.needs).forEach(
            ([key, value]) => {
              refForm.current!.setFieldError(key, value as string);
            },
          );

          refForm.current.setFieldTouched('offerNeeds');
        }
      } else {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      }
    },
  });

  const refForm = useRef<FormikProps<ProjectNeeds>>(null);

  const getInitialValues = (): ProjectNeeds => {
    const values: ProjectNeeds = {};

    const projectHaveNeeds = !(
      projectQuery.data == null || projectQuery.data.needs == null
    );
    if (projectHaveNeeds) {
      getOfferNeedTypeKey().forEach(
        type => (values[type] = projectQuery.data!.needs![type]),
      );
    } else {
      getOfferNeedTypeKey().forEach(
        type => (values[type] = OfferNeedWeight.PAS_DE_BESOIN),
      );
    }

    return values;
  };

  const validation = (values: ProjectNeeds) => {
    if (!collectingNeed) {
      return {};
    }

    return Object.entries(values).find(([, value]) => value == null) == null
      ? {}
      : { message: 'Vous devez sélectionner tous les besoins.' };
  };

  const updateProjectContext = (values: ProjectNeeds) => {
    setDataCurrent(
      produce(state => {
        if (!state.project || !state.project.subscriptions) {
          return state;
        }

        state.project.needs = getOfferNeedWeightWithSubNeed(values);
      }),
    );
  };
  const onChange = (values: ProjectNeeds) => {
    updateProjectContext(values);
  };

  const onSubmit = (values: ProjectNeeds) => {
    mutation.mutate({
      needs: collectingNeed ? getOfferNeedWeightWithSubNeed(values) : undefined,
    });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" gap={2}>
      <Grid item xs={12}>
        <Stack direction="row">
          <Typography variant="h3" flexGrow={1}>
            Besoins
          </Typography>
        </Stack>
      </Grid>

      {contextStatus === 'done' && projectQuery.data ? (
        <>
          {projectQuery.data?.indirect && (
            <Grid item xs={12}>
              <Card sx={{ px: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1">
                    Souhaitez-vous recueillir les besoins de votre client via
                    CLARISS ?
                  </Typography>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={collectingNeed}
                    onChange={(_, checked) => isCollectingNeed(checked)}
                  />
                </Stack>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Formik
              innerRef={refForm}
              initialValues={getInitialValues()}
              onSubmit={values => {
                return onSubmit(values);
              }}
              validate={validation}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => {
                const errorMessage =
                  Object.keys(errors).length != 0
                    ? (errors as { message: string }).message
                    : null;
                const haveBeenSubmit = Object.keys(touched).length != 0;
                return (
                  <Form>
                    <FormikObserver onChange={onChange} />
                    <Grid
                      container
                      justifyContent="center"
                      spacing={{ sm: 2, xs: 1 }}
                    >
                      <Grid item xs={12}>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="stretch"
                          spacing={2}
                        >
                          {collectingNeed &&
                            Object.entries(values).map(([key, value]) => {
                              const needData =
                                offerNeedDatas[key as OfferNeedType];
                              return (
                                <Card
                                  key={key}
                                  sx={{
                                    height: '100%',
                                    p: 2,
                                  }}
                                >
                                  <Stack justifyContent="space-between" gap={1}>
                                    {needData && (
                                      <Stack direction="row" spacing={1}>
                                        <Box>
                                          {React.createElement(needData.icon, {
                                            size: 'medium',
                                          })}
                                        </Box>
                                        <Typography>
                                          {needData.label}
                                        </Typography>
                                      </Stack>
                                    )}
                                    <ToggleButtonGroupSplit
                                      orientation={
                                        screenSizeDownSM
                                          ? 'vertical'
                                          : 'horizontal'
                                      }
                                      value={value}
                                      sx={{
                                        width: '100%',
                                      }}
                                      style={{ marginLeft: 0 }}
                                      exclusive
                                      onChange={(e, value) =>
                                        setFieldValue(key, value)
                                      }
                                    >
                                      <OfferNeedToggleButton
                                        value={OfferNeedWeight.PAS_DE_BESOIN}
                                      />
                                      <OfferNeedToggleButton
                                        value={OfferNeedWeight.BASIQUE}
                                      />
                                      <OfferNeedToggleButton
                                        value={OfferNeedWeight.EQUILIBRE}
                                      />
                                      <OfferNeedToggleButton
                                        value={OfferNeedWeight.CONFORTABLE}
                                      />
                                    </ToggleButtonGroupSplit>
                                  </Stack>
                                </Card>
                              );
                            })}
                        </Stack>
                      </Grid>

                      {errorMessage && haveBeenSubmit && (
                        <Grid item xs={12}>
                          <FormHelperText error>{errorMessage}</FormHelperText>
                        </Grid>
                      )}

                      <Grid item sm="auto" xs={12}>
                        <Button
                          fullWidth
                          color="default"
                          disabled={mutation.isPending}
                          onClick={() => {
                            navigateToPreviousStep(CURRENT_STEP, id, navigate);
                          }}
                          sx={{ px: 4 }}
                        >
                          Retour
                        </Button>
                      </Grid>

                      <Grid item sm="auto" xs={12}>
                        <Button
                          fullWidth
                          disabled={mutation.isPending}
                          onClick={() => handleSubmit()}
                          sx={{ px: 4 }}
                        >
                          {mutation.isPending ? (
                            <CircularProgress color="inherit" size={28} />
                          ) : (
                            'Valider'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </>
      ) : contextStatus === 'error' ? (
        <Grid
          item
          xs={12}
          sx={{
            height: 200,
          }}
        >
          <CardError
            status={
              projectQuery.error instanceof RestError
                ? projectQuery.error.status
                : undefined
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" animation="wave" height="200px" />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectNeedsPage;
