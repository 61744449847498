import { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import { useProjectContext } from 'components/Context/ProjectContext';
import FormOfferSelect, {
  FormOfferSelectRef,
  FormOfferSelectValues,
} from 'content/form/FormOfferSelect';
import { Skeleton } from '@mui/material';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';

const CURRENT_STEP = ProjectStep.SUBSCRIPTIONS;

const ProjectOffersPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const navigate = useNavigate();

  const { id } = useParams();
  const refForm = useRef<FormOfferSelectRef>(null);

  if (!id) return <></>;

  const contextProject = useProjectContext();

  const queryClient = useQueryClient();
  const projectQuery = useQuery(projectQueries.getById(id));
  const offersQuery = useQuery(projectQueries.getByIdOffers(id));

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError || offersQuery.isError) return 'error';
    if (
      projectQuery.isFetching ||
      offersQuery.isFetching ||
      !projectQuery.data ||
      !offersQuery.data
    )
      return 'loading';
    return 'done';
  }, [projectQuery, offersQuery]);

  const [loading, setLoading] = useState<boolean>(false);

  const getInitialValues = (): FormOfferSelectValues => {
    return {
      subscriptions: projectQuery.data?.subscriptions,
    };
  };

  const submit = async () => {
    setLoading(true);

    refForm.current
      ?.submitForm()
      .then(project => {
        if (!project) return;
        // TODO : mutate.
        queryClient.setQueryData(['projects', id], () => project);
        navigateToNextStep(CURRENT_STEP, id, navigate);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container justifyContent="center" alignItems="center" gap={2}>
      <Grid item xs={12}>
        <Typography variant="h3" flexGrow={1}>
          Offres
          <Chip
            sx={{
              ml: 1,
            }}
            label={
              contextProject.dataCurrent?.project?.subscriptions?.length || 0
            }
          />
        </Typography>
      </Grid>

      {contextStatus === 'done' && projectQuery.data && offersQuery.data ? (
        <>
          <Grid item xs={12}>
            <FormOfferSelect
              ref={refForm}
              project={projectQuery.data}
              offers={offersQuery.data}
              initialValues={getInitialValues()}
              onChangeValues={_ => {
                contextProject.setDataCurrent(prev => {
                  if (!prev.project) return prev;
                  return {
                    ...prev,
                    project: {
                      ...prev.project,
                      subscriptions: _.subscriptions,
                    },
                  };
                });
              }}
              maxOffers={1}
            />
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              color="default"
              onClick={() => navigateToPreviousStep(CURRENT_STEP, id, navigate)}
              sx={{ px: 4 }}
            >
              Retour
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              type="submit"
              disabled={loading}
              sx={{ px: 4 }}
              onClick={() => submit()}
            >
              Valider
            </Button>
          </Grid>
        </>
      ) : contextStatus === 'error' ? (
        <Grid
          item
          xs={12}
          sx={{
            height: 200,
          }}
        >
          <CardError
            status={
              projectQuery.error instanceof RestError
                ? projectQuery.error.status
                : undefined
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" animation="wave" height="200px" />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectOffersPage;
