import { queryOptions, useMutation } from '@tanstack/react-query';
import { useAccessToken } from 'utils/api/api';
import { Estimation, EstimationRequest } from 'models/oav/Estimation.models';
import {
  createEstimation,
  fetchEstimationDocumentTypes,
} from 'api/oav/Estimation.api';

const useCreateMutation = (
  onSuccess?: (
    data: Estimation | Blob,
    variables: {
      projectId: string;
      subscriptionId: string;
      payload: EstimationRequest;
      accept: 'application/zip' | 'application/json';
    },
  ) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  return useMutation({
    mutationFn: (params: {
      projectId: string;
      subscriptionId: string;
      payload: EstimationRequest;
      accept: 'application/zip' | 'application/json';
    }) =>
      createEstimation(
        accessToken,
        params.projectId,
        params.subscriptionId,
        params.payload,
        params.accept,
      ),
    onSuccess: (estimation: Estimation | Blob, variables) => {
      onSuccess?.(estimation, variables);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

const getDocumentTypesOptions = (
  projectId: string,
  subscriptionId?: string,
) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: [
      'projects',
      projectId,
      'subscription',
      subscriptionId,
      'estimation-document-types',
    ],
    queryFn: () =>
      fetchEstimationDocumentTypes(token, projectId, subscriptionId ?? ''),
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    enabled: !!subscriptionId,
  });
};

export const estimationMutations = {
  useCreate: useCreateMutation,
};

export const estimationQueryOptions = {
  getDocumentTypes: getDocumentTypesOptions,
};
