import {
  Estimation,
  EstimationDocumentTypes,
  EstimationRequest,
} from 'models/oav/Estimation.models';
import { getRequest, postRequest } from 'utils/queries/queries.utils';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const createEstimation = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: EstimationRequest,
  accept: 'application/zip' | 'application/json',
): Promise<Estimation | Blob> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/estimation`;

  return postRequest(
    url,
    payload,
    accessToken,
    {
      'Content-Type': 'application/json',
      Accept: accept,
    },
    accept === 'application/json' ? 'json' : 'blob',
  );
};

export const fetchEstimationDocumentTypes = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<EstimationDocumentTypes> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/estimation/document-types`;

  return getRequest(url, accessToken);
};
