import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { OfferDescription } from 'content/offer/OfferDescription';
import FormSubscription from 'content/subscription/FormSubscription';

import { Subscription } from 'models/oav/Subscription.models';
import { Project } from 'models/oav/Project.models';
import { Offer } from 'models/referentiels/Offer.model';
import { PromotionCard } from 'components/PromotionCard/PromotionCard';
import React from 'react';

export type CardSubscriptionProps = {
  project: Project;
  subscription: Subscription;
  offer: Offer;
  displayPromotions?: boolean;
  divider?: boolean;
  showForm?: boolean;
  onUpdate?: (subscription: Subscription) => void;
  children?: JSX.Element;
};

const CardSubscription = (props: CardSubscriptionProps) => {
  return (
    <Card color="darken" sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OfferDescription offer={props.offer} />
            </Grid>

            {props.displayPromotions &&
              props.offer.promotions?.map((promotion, index) => (
                <Grid key={index} item xs={12}>
                  <PromotionCard promotion={promotion} />
                </Grid>
              ))}

            {props.showForm && (
              <Grid item xs={12}>
                <FormSubscription
                  disabled={!props.onUpdate}
                  project={props.project}
                  offer={props.offer}
                  subscription={props.subscription}
                  onChange={props.onUpdate}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {props.children && (
          <>
            {props.divider && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}

            <Grid item xs={12}>
              {props.children}
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default CardSubscription;
