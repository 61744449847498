import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

import ButtonClose from 'components/Button/ButtonClose';
import CardError from 'components/Card/CardError';

import { Document } from 'models/oav/Document.model';

interface PreviewDocumentProps {
  document?: Document | null | Blob;
  loading?: boolean;
  onClose?: () => void;
}

const isDocument = (
  document: Document | null | Blob | undefined,
): document is Document => {
  return !!(document as Document).downloadUrl;
};

const isBlob = (
  document: Document | null | Blob | undefined,
): document is Blob => document instanceof Blob;

const PreviewDocument: React.FC<PreviewDocumentProps> = (
  props: PreviewDocumentProps,
) => {
  const downloadUrl = isDocument(props.document)
    ? props.document?.downloadUrl
    : null;

  const documentQuery = useQuery({
    queryKey: ['document', downloadUrl],
    queryFn: () =>
      fetch(downloadUrl!).then(async response => await response.blob()),
    enabled: !!downloadUrl,
    staleTime: 0,
    gcTime: 0,
  });

  const [blobUrl, setBlobUrl] = useState<string | undefined>(undefined);
  const isEmbed = !(
    isBlob(props.document) ? props.document.type : props.document?.mimeType
  )?.startsWith('image/');

  useEffect(() => {
    const data = isBlob(props.document) ? props.document : documentQuery.data;
    if (data && !!props.document) {
      setBlobUrl(URL.createObjectURL(data));
    }
  }, [documentQuery.data, props.document]);

  return (
    <Card
      sx={{
        position: 'relative',
        display: 'flex',
        height: '100%',
        p: 0,
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {props.onClose && (
        <ButtonClose sx={{ ml: 'auto' }} onClick={props.onClose} />
      )}
      <div style={{ height: '100%' }}>
        {documentQuery.error ? (
          <CardError />
        ) : props.loading ||
          documentQuery.isLoading ||
          !blobUrl ||
          !props.document ? (
          <Skeleton height="100%" width="100%" sx={{ transform: 'unset' }} />
        ) : (
          <>
            {!isEmbed ? (
              <img src={blobUrl} style={{ width: '100%' }} />
            ) : (
              <embed
                key={isDocument(props.document) ? props.document.id : undefined}
                src={blobUrl}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default PreviewDocument;
