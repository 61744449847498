import { ReactComponentLike } from 'prop-types';
import { DocumentIDIcon } from 'components/Icon/DocumentIDIcon';
import { DocumentIcon } from 'components/Icon/DocumentIcon';
import { DocumentRIBIcon } from 'components/Icon/DocumentRIBIcon';
import { DocumentMajorityIcon } from 'components/Icon/DocumentMajorityIcon';

export interface StaticDocument {
  id: string;
  mimeType: string;
  filename: string;
  type: StaticDocumentType;
  downloadUrl: string;
}

export interface StaticDocumentType {
  label: string;
  code: string;
}

export enum StaticDocumentTypeCodes {
  DOCUMENT_INFORMATION = 'DOCUMENT_INFORMATION',
  DOCUMENT_INFORMATION_SURCO = 'DOCUMENT_INFORMATION_SURCO',
  CONDITIONS_GENERALES = 'CONDITIONS_GENERALES',
  CONDITIONS_GENERALES_SURCO = 'CONDITIONS_GENERALES_SURCO',
  REGLEMENT_MUTUALISTE = 'REGLEMENT_MUTUALISTE',
  REGLEMENT_MUTUALISTE_SURCO = 'REGLEMENT_MUTUALISTE_SURCO',
  ANIP = 'ANIP',
  CONDITIONS_GENERALES_ASSISTANCE = 'CONDITIONS_GENERALES_ASSISTANCE',
  PLAQUETTE_MEDECIN_DIRECT = 'PLAQUETTE_MEDECIN_DIRECT',
  GUIDE_SANTE_ADHERENT = 'GUIDE_SANTE_ADHERENT',
  TABLEAU_GARANTIES = 'TABLEAU_GARANTIES',
  STATUTS_GSMC = 'STATUTS_GSMC',
}

export const staticDocumentTypeData: {
  [key in StaticDocumentTypeCodes]?: { label: string; icon?: string };
} = {
  [StaticDocumentTypeCodes.DOCUMENT_INFORMATION]: {
    label: "Le Document d'Information des garanties proposées",
    icon: 'ℹ️',
  },
  [StaticDocumentTypeCodes.REGLEMENT_MUTUALISTE]: {
    label: 'Le Règlement Mutualiste des garanties proposées',
    icon: '📑',
  },
  [StaticDocumentTypeCodes.CONDITIONS_GENERALES_ASSISTANCE]: {
    label: "Les Conditions Générales d'Assistance",
    icon: '📃',
  },
  [StaticDocumentTypeCodes.CONDITIONS_GENERALES]: {
    label: 'Les Conditions Générales des garanties proposées',
    icon: '📃',
  },
  [StaticDocumentTypeCodes.STATUTS_GSMC]: {
    label: 'Les statuts de la Mutuelle GSMC',
    icon: '🏢',
  },
  [StaticDocumentTypeCodes.ANIP]: {
    label: "Les statuts de l'ANIP",
    icon: '🏥',
  },
};

export interface DocumentDownload {
  url: string;
}

export interface SupportingDocumentType {
  code: SupportingDocumentTypeCode;
  label: string;
  description: string;
}

export enum SupportingDocumentTypeCode {
  ID = 'ID',
  CPAM = 'CPAM',
  BANK_DETAILS = 'BANK_DETAILS',
  CHILD_MAJORITY = 'CHILD_MAJORITY',
  KBIS = 'KBIS',
}

export const supportingDocumentIcons: {
  [key in SupportingDocumentTypeCode]: { icon: ReactComponentLike };
} = {
  [SupportingDocumentTypeCode.ID]: { icon: DocumentIDIcon },
  [SupportingDocumentTypeCode.CPAM]: { icon: DocumentIcon },
  [SupportingDocumentTypeCode.BANK_DETAILS]: { icon: DocumentRIBIcon },
  [SupportingDocumentTypeCode.CHILD_MAJORITY]: { icon: DocumentMajorityIcon },
  [SupportingDocumentTypeCode.KBIS]: { icon: DocumentIcon },
};

export const supportingDocumentSort: {
  [key in SupportingDocumentTypeCode]: { sort: number };
} = {
  [SupportingDocumentTypeCode.ID]: { sort: 1 },
  [SupportingDocumentTypeCode.KBIS]: { sort: 2 },
  [SupportingDocumentTypeCode.BANK_DETAILS]: { sort: 3 },
  [SupportingDocumentTypeCode.CPAM]: { sort: 4 },
  [SupportingDocumentTypeCode.CHILD_MAJORITY]: { sort: 5 },
};
