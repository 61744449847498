import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import { useAppContext } from 'components/Context/AppContext';
import DialogSolutionEdition from 'content/solution/DialogSolutionEdition';
import CardSubscription from 'content/subscription/CardSubscription';
import GridSubscriptionSolutions from 'content/subscription/GridSubscriptionSolutions';

import { ProjectStep } from 'models/oav/ProjectStep.models';
import { Subscription } from 'models/oav/Subscription.models';
import { Solution } from 'models/oav/Solution.models';

import { ApiResponse } from 'models/api.model';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { projectStepMutations } from 'api/oav/ProjectStepQuery.api';

import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { RestError } from 'errors/RestError';
import { subscriptionMutations } from 'api/oav/SubscriptionQuery.api';
import { PromotionList } from 'components/PromotionList/PromotionList';
import Divider from '@mui/material/Divider';

const CURRENT_STEP = ProjectStep.SOLUTIONS;

const ProjectSolutionsPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { addSnackbar } = useAppContext();

  if (!id) return <></>;

  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError || offersMapQuery.isError) return 'error';
    if (
      projectQuery.isLoading ||
      offersMapQuery.isLoading ||
      !projectQuery.data ||
      !offersMapQuery.data
    )
      return 'loading';
    return 'done';
  }, [projectQuery, offersMapQuery]);

  const getSubscriptionFromOfferCode = (code: string) => {
    const subscriptions = projectQuery.data?.subscriptions;
    if (!subscriptions) return;

    const sub = subscriptions.find(_ => _.offerCode === code);
    if (sub) return sub;
    else if (subscriptions.length > 0) return subscriptions[0];
  };

  const [subscriptionCurrent, setSubscriptionCurrent] = useState<
    Subscription | undefined
  >(getSubscriptionFromOfferCode(searchParams.get('offre') as string));

  const [promotionIds, setPromotionIds] = useState<string[]>(
    subscriptionCurrent?.promotionIds || [],
  );
  const subscriptionPromotionsMutation =
    subscriptionMutations.updateSubscriptionPromotionsMutation(
      id,
      subscriptionCurrent?.id || '',
      {
        onError: error => {
          addSnackbar(
            getDefaultErrorSnackBar(
              'Une erreur est survenue durant la mise à jour des promotions.',
            ),
          );
        },
      },
    );

  useEffect(() => {
    setSubscriptionCurrent(
      getSubscriptionFromOfferCode(searchParams.get('offre') as string),
    );
  }, [projectQuery?.data, promotionIds]);

  useEffect(() => {
    if (!subscriptionCurrent?.offerCode) return;
    setSearchParams(prev => ({
      ...prev,
      offre: subscriptionCurrent.offerCode,
    }));
  }, [subscriptionCurrent?.offerCode]);

  const offer = useMemo(() => {
    if (subscriptionCurrent && offersMapQuery.data) {
      return offersMapQuery.data[subscriptionCurrent.offerCode];
    }
  }, [subscriptionCurrent, offersMapQuery.data]);

  const [error, setError] = useState<string>();
  const mutation = projectStepMutations.updateProjectStepSolutions(id, {
    onSuccess: () => {
      navigateToNextStep(CURRENT_STEP, id, navigate);
    },
    onError: error => {
      if (error instanceof RestError && error.status === 400) {
        setError((error.response as ApiResponse<any>).detail);
      } else {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      }
    },
  });

  const handlePromotion = (promotionId: string, checked: boolean) => {
    if (subscriptionCurrent) {
      const promotionIds: string[] = checked
        ? [...(subscriptionCurrent.promotionIds || []), promotionId]
        : subscriptionCurrent.promotionIds?.filter(p => p !== promotionId) ||
          [];
      setPromotionIds(promotionIds);
      subscriptionPromotionsMutation.mutate({ promotionIds: promotionIds });
    }
  };

  const [dialogSolutionContext, setDialogSolutionContext] = useState<{
    open: boolean;
    order?: number;
    solutionToUpdate?: Solution;
  }>({
    open: false,
  });

  if (
    !projectQuery.data ||
    !offersMapQuery.data ||
    !subscriptionCurrent ||
    !offer
  ) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" gap={2}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          gap={1}
        >
          <Typography variant="h3" flexGrow={1}>
            Devis
          </Typography>
          {contextStatus === 'done' &&
            offersMapQuery.data &&
            projectQuery.data &&
            projectQuery.data.subscriptions?.length &&
            projectQuery.data.subscriptions?.length > 1 && (
              <ToggleButtonGroup
                exclusive
                color="primary"
                size="small"
                value={subscriptionCurrent?.offerCode}
                onChange={(e, v) => {
                  setSubscriptionCurrent(getSubscriptionFromOfferCode(v));
                }}
              >
                {projectQuery.data.subscriptions.map(_ => (
                  <ToggleButton value={_.offerCode} key={_.offerCode}>
                    {offersMapQuery.data[_.offerCode].label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
        </Stack>
      </Grid>

      {projectQuery.data && subscriptionCurrent && (
        <DialogSolutionEdition
          project={projectQuery.data}
          subscription={subscriptionCurrent}
          open={dialogSolutionContext.open}
          onClose={() => setDialogSolutionContext({ open: false })}
          solutionOrder={
            dialogSolutionContext.solutionToUpdate?.order ??
            dialogSolutionContext.order ??
            1
          }
          initialSolution={dialogSolutionContext.solutionToUpdate}
        />
      )}

      <Grid item xs={12}>
        <CardSubscription
          project={projectQuery.data}
          divider
          subscription={subscriptionCurrent}
          offer={offer}
          showForm
          onUpdate={_ => setSubscriptionCurrent(_)}
        >
          <Stack gap={2}>
            {!projectQuery.data?.indirect &&
              subscriptionCurrent &&
              offersMapQuery.data[subscriptionCurrent.offerCode].promotions && (
                <>
                  <PromotionList
                    promotions={
                      offersMapQuery.data[subscriptionCurrent.offerCode]
                        .promotions || []
                    }
                    selectedPromotions={promotionIds}
                    onPromotionSelected={(promotionId, selected) =>
                      handlePromotion(promotionId, selected)
                    }
                  />
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}

            <GridSubscriptionSolutions
              project={projectQuery.data}
              subscription={subscriptionCurrent}
              offer={offer}
              selectedSolutionId={subscriptionCurrent.solutionId}
              onCreateSolution={_ => {
                setDialogSolutionContext({ open: true, order: _ });
              }}
              onDeleteSolution={_ => {}}
              onClickSolution={_ =>
                setDialogSolutionContext({
                  open: true,
                  order: _.order,
                  solutionToUpdate: _,
                })
              }
            />

            {error && <FormHelperText error>{error}</FormHelperText>}
          </Stack>
        </CardSubscription>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <Button
          fullWidth
          color="default"
          disabled={mutation.isPending}
          onClick={() => navigateToPreviousStep(CURRENT_STEP, id, navigate)}
          sx={{ px: 4 }}
        >
          Retour
        </Button>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <Button
          fullWidth
          type="submit"
          disabled={mutation.isPending}
          sx={{ px: 4 }}
          onClick={() => {
            mutation.mutate({});
          }}
        >
          Valider
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectSolutionsPage;
